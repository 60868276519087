<template>
  <div class="black--text">
    <!-- versio pc -->
    <div v-if="!mobil">
      <div style="border: 1px solid #d9d9d9">
        <div style="width: 80%; margin: 0 auto">
          <v-row class="mt-10 font-weight-bold">
            <v-col cols="6"></v-col>
            <v-col cols="2" align="center" aria-label="Cantidad">{{ $t("QUANTITAT") }}</v-col>
            <v-col cols="2" align="right" aria-label="Precio">{{ $t("PREU") }}</v-col>
            <v-col cols="2" align="right" aria-label="Total">{{ $t("TOTAL2") }}</v-col>
          </v-row>
          <v-divider class="mt-1" style="border-color: #d9d9d9"></v-divider>
          <div v-for="(preu, index) in preus" :key="index">
            <v-row class="mt-1">
              <v-col cols="6" class="font-weight-bold">
                {{ preu.descripcio }}
              </v-col>
              <v-col cols="2" align="center">
                <v-text-field v-model="entrades[index]" dense style="width: 40px; border: 0px" type="number" min="0"
                  class="mt-n1" aria-label="Cantidad">
                </v-text-field>
              </v-col>
              <v-col cols="2" align="right" aria-label="Precio">{{ format(preu.preu) }}</v-col>
              <v-col v-if="preu.info" cols="2" align="right" style="position: relative; right: -35px"
                aria-label="Total">{{ total(preu.preu,
      entrades[index]) }}
                <v-icon style="
                    float: right;
                    position: relative;
                    right: -20px;
                    top: -6px;
                  " large @click="infoPreu()" aria-label="Información">mdi-information-outline</v-icon>
              </v-col>
              <v-col v-else cols="2" align="right">{{ total(preu.preu, entrades[index]) }}
              </v-col>
            </v-row>

            <v-divider style="border-color: #d9d9d9"></v-divider>
          </div>

          <div>
            <v-row class="mt-1" v-if="mostrarVals == 'S'">
              <v-col cols="6" class="font-weight-bold">
                {{ $t("CODIDESCOMPTE") }}
              </v-col>
              <v-col cols="2" align="center">
                <!-- Quantitat vals -->
                {{ vals.length }}
              </v-col>
              <v-col cols="4" align="right">
                <!-- Boto dialeg vals -->
                <v-btn depressed color="#000000" class="dlgBtnPC mt-n1 mb-3" @click="dlgVals = true" aria-label="Vales">
                  {{ $t("ENTRARVALS") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-divider style="border-color: #d9d9d9"></v-divider>
          </div>
          <v-row class="mt-2 font-weight-bold">
            <v-col align="end">Subtotal {{ subtotal() }}</v-col>
          </v-row>
          <v-row justify="center" class="mt-5" v-if="fidelity == 'S'">
            <v-col cols="5" class="mt-2 font-weight-bold">
              {{
      tipusWeb != "O" ? $t("TARGETAFIDELITY1") : $t("TARGETAFIDELITY")
    }}
            </v-col>
            <v-col @click="dlgTargeta2 = true">
              <v-text-field outlined dense disabled v-model="nTargeta" aria-label="Tarjeta fidelidad">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn depressed style="border-radius: 17px" min-width="150" color="#58564f" class="mt-5 mb-10 mr-2"
              @click="anterior()" aria-label="Volver">
              {{ $t("TORNAR") }}
            </v-btn>
            <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="mt-5 mb-10 ml-2"
              @click="seguent()" aria-label="Siguiente">
              {{ $t("SEGUENT") }}
            </v-btn>
          </v-row>
          <div class="mt-5"></div>
        </div>
      </div>

      <!-- Dialegs targetes -->
      <v-dialog v-model="dlgTargeta1" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ tipusWeb != "O" ? $t("TENSFIDELITY1") : $t("TENSFIDELITY") }}
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mt-3 mr-3" @click="
      dlgTargeta1 = false;
    dlgTargeta2 = true;
    " aria-label="Si">
                  {{ $t("SITINC") }}
                </v-btn>
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mt-3 ml-3"
                  @click="dlgTargeta1 = false; nTargeta = '';" aria-label="No">
                  {{ $t("NOTINC") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" class="mb-3">
                <a :href="infoFidelity()" class="font-weight-bold black--text">
                  {{
      tipusWeb != "O"
        ? $t("COMOBTENIRFIDELITY1")
        : $t("COMOBTENIRFIDELITY")
    }}
                </a>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dlgTargeta2" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{
      tipusWeb != "O"
        ? $t("INGRESAFIDELITY1")
        : $t("INGRESAFIDELITY")
    }}
              </v-col>
            </v-row>

            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-text-field color="black" outlined dense autofocus v-model="nTargeta" aria-label="Tarjeta">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("CONTRASENYA") }}
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-text-field color="black" outlined dense hide-details v-model="contrasenya"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass" aria-label="Contraseña">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-checkbox class="black--text" v-model="guardarDadesTargeta" :label="$t('GUARDARDADESTARGETA')"
                  hide-details>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-n2 mb-4">
              <v-col class="dlgInput mt-3">
                <a v-if="LinkCanviPinTargeta != ''" :href="LinkCanviPinTargeta">{{ $t("RESTABLIRCONTRASENYA") }}</a>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mr-3" @click="comprovarTargeta()"
                  aria-label="Aceptar">
                  {{ $t("ACCEPTAR") }}
                </v-btn>
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="
      dlgTargeta2 = false;
    nTargeta = '';
    " aria-label="Descartar">
                  {{ $t("NOGRACIES") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <!-- Dialeg Vals -->
      <v-dialog v-model="dlgVals" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("TENSVAL") }}
              </v-col>
            </v-row>

            <v-row align="center" class="mt-n4">
              <v-col cols="8" class="mt-5">
                <v-text-field color="black" outlined dense autofocus v-model="val" aria-label="Vale">
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-0">
                <v-btn depressed color="#000000" class="dlgBtnPC" style="width: 100%" :disabled="!btnValidarVal"
                  @click="validarVal()" aria-label="Validar">
                  {{ $t("VALIDAR") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col>
                <v-simple-table height="150px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left black--text">{{ $t("VALS") }}</th>
                        <th style="width: 20px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in vals" :key="index">
                        <td>{{ item.msg }}</td>
                        <td style="width: 20px">
                          <v-icon small color="#e31d1a" @click="esborrarVal(index)"
                            aria-label="Borrar">mdi-close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="dlgVals = false"
                  aria-label="Aceptar">
                  {{ $t("ACCEPTAR") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>
    </div>
    <!-- versio mobil -->
    <div v-else>
      <table style="width: 100%">
        <!--
        <v-row class="text-h6 font-weight-bold">
          <v-col cols="9"> {{ preu.descripcio }} </v-col>
          <v-col align="right" cols="3">
            <v-text-field
              v-model="entrades[index]"
              color="black"
              outlined
              dense
              style="width: 50px"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n16 text-body-1">
          <v-col cols="10"> {{ format(preu.preu) }}</v-col>
        </v-row>
        -->
        <tr v-for="(preu, index) in preus" :key="index">
          <td style="padding-bottom: 12px">
            <div style="font-weight: bold; font-size: 16px">
              {{ preu.descripcio }}
            </div>
            <div style="font-size: 16px">
              {{ format(preu.preu) }}
            </div>
          </td>
          <td width="54px" style="padding-bottom: 12px">
            <v-text-field v-model="entrades[index]" color="black" outlined hide-details dense style="width: 50px"
              aria-label="Cantidad">
            </v-text-field>
          </td>
          <td width="24px" style="padding-bottom: 12px">
            <v-icon v-if="preu.info" style="margin-left: 1px" large @click="infoPreu()"
              aria-label="Información">mdi-information-outline</v-icon>
          </td>
        </tr>
      </table>
      <!-- Vals -->
      <div v-if="mostrarVals == 'S'">
        <table width="100%" class="mb-10">
          <tr>
            <td cols="9" class="font-weight-bold">Código descuento</td>
            <td align="center">
              <!-- Quantitat vals -->
              {{ vals.length }}
            </td>
            <td width="24px"></td>
          </tr>
        </table>
        <v-row>
          <v-col align="center">
            <!-- Boto dialeg vals -->
            <v-btn depressed color="#000000" class="dlgBtnPC mt-n1 mb-3" @click="dlgVals = true" aria-label="Vales">
              {{ $t("ENTRARVALS") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-if="fidelity == 'S'">
        <v-row justify="center" class="mt-5 text-h6">
          <v-col cols="12">
            {{
      tipusWeb != "O" ? $t("TARGETAFIDELITY1") : $t("TARGETAFIDELITY")
    }}
          </v-col>
        </v-row>
        <v-row justify="center" class="text-h6">
          <v-col cols="12" @click="dlgTargeta2 = true">
            <v-text-field outlined dense disabled v-model="nTargeta" aria-label="Tarjeta fidelidad">
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-row justify="center">
        <v-btn depressed style="border-radius: 17px" min-width="150" color="#58564f" class="mt-10 mb-10 mr-2"
          @click="anterior()" aria-label="Volver">
          {{ $t("TORNAR") }}
        </v-btn>
        <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="mt-10 mb-10 ml-2"
          @click="seguent()" aria-label="Siguiente">
          {{ $t("SEGUENT") }}
        </v-btn>
      </v-row>

      <!-- Dialegs targetes -->
      <v-dialog v-model="dlgTargeta1" width="500">
        <v-sheet color="white" style="border-radius: 8px">
          <v-main>
            <div class="mt-6">
              <h4 style="text-align: center">
                {{ tipusWeb != "O" ? $t("TENSFIDELITY1") : $t("TENSFIDELITY") }}
              </h4>
            </div>
            <div>
              <v-btn depressed min-width="300" color="#000000" class="dlgBtn mb-3 mt-3" @click="
      dlgTargeta1 = false;
    dlgTargeta2 = true;
    " aria-label="Si">
                {{ $t("SITINC") }}
              </v-btn>
            </div>
            <v-btn depressed color="#000000" class="dlgBtn mb-1 mt-3" @click="dlgTargeta1 = false; nTargeta = '';"
              aria-label="No">
              {{ $t("NOTINC") }}
            </v-btn>
            <div style="text-align: center">
              <a :href="infoFidelity()" class="font-weight-bold black--text">
                {{
      tipusWeb != "O"
        ? $t("COMOBTENIRFIDELITY1")
        : $t("COMOBTENIRFIDELITY")
    }}
              </a>
            </div>
          </v-main>
        </v-sheet>
      </v-dialog>

      <v-dialog v-model="dlgTargeta2" width="500">
        <v-sheet color="white" style="border-radius: 8px">
          <v-main>
            <div class="mt-6 mb-2">
              <h4 style="text-align: center">
                {{
      tipusWeb != "O"
        ? $t("INGRESAFIDELITY1")
        : $t("INGRESAFIDELITY")
    }}
              </h4>
            </div>
            <div class="dlgInput">
              <v-text-field color="black" outlined dense autofocus v-model="nTargeta" aria-label="Tarjeta">
              </v-text-field>
            </div>
            <div class="mt-0 mb-2">
              <h4 style="text-align: center">Contraseña</h4>
            </div>
            <div class="dlgInput">
              <v-text-field color="black" outlined dense hide-details autofocus v-model="contrasenya"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass" aria-label="Contraseña">
              </v-text-field>
            </div>
            <div class="dlgInput">
              <v-checkbox class="black--text" v-model="guardarDadesTargeta" :label="$t('GUARDARDADESTARGETA')">
              </v-checkbox>
            </div>
            <div class="dlgInput mb-4">
              <a v-if="LinkCanviPinTargeta != ''" :href="LinkCanviPinTargeta">{{
      $t("RESTABLIRCONTRASENYA")
    }}</a>
            </div>
            <div>
              <v-btn depressed color="#000000" class="dlgBtn mb-1" @click="
    dlgTargeta2 = false;
    nTargeta = '';
    " aria-label="Descartar">
                No, gracias
              </v-btn>
            </div>
            <v-btn depressed color="#000000" class="dlgBtn mb-3 mt-3" @click="comprovarTargeta()" aria-label="Aceptar">
              Aceptar
            </v-btn>
          </v-main>
        </v-sheet>
      </v-dialog>

      <!-- Dialeg Vals -->
      <v-dialog v-model="dlgVals" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("TENSVAL") }}
              </v-col>
            </v-row>

            <v-row align="center" class="mt-n4">
              <v-col cols="8" class="mt-5">
                <v-text-field color="black" outlined dense autofocus v-model="val" aria-label="Vale">
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-0">
                <v-btn depressed color="#000000" class="dlgBtnPC" style="width: 100%" :disabled="!btnValidarVal"
                  @click="validarVal()" aria-label="Validar">
                  {{ $t("VALIDAR") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col>
                <v-simple-table height="150px">

                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left black--text">Vales</th>
                        <th style="width: 20px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in vals" :key="index">
                        <td>{{ item.msg }}</td>
                        <td style="width: 20px">
                          <v-icon small color="#e31d1a" @click="esborrarVal(index)"
                            aria-label="Borrar">mdi-close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="dlgVals = false"
                  aria-label="Aceptar">
                  {{ $t("ACCEPTAR") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";

const FN = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

export default {
  data() {
    return {
      showPass: false,
      mobil: window.innerWidth <= 1300,
      preus: [],
      missatge: false,
      dlgTargeta1: false,
      dlgTargeta2: false,
      dlgVals: false,
      nTargeta: "",
      entradesAnt: [],
      entrades: [],
      contrasenya: "",
      val: "",
      vals: [],
      numButaques: 0,
      guardarDadesTargeta: false,
      LinkCanviPinTargeta: sessionStorage.getItem(
        "webtpv21_linkCanviPinTargeta"
      ),
      fidelity: false,
      btnValidarVal: true,
      tipusWeb: sessionStorage.getItem("webtpv21_tipusWeb"),
      mostrarVals: sessionStorage.getItem("webtpv21_hiHaVals"),
    };
  },
  watch: {
    vals() {
      this.repartirEntrades();
    },
    entrades() {
      //this.repartirEntrades();

      // Mostrar info si un preu amb descompre passa a > 0

      for (var i = 0; i < this.preus.length; i++) {
        var qAnt = parseInt(this.entradesAnt[i]) || 0;
        var q = parseInt(this.entrades[i]) || 0;

        if (qAnt == 0 && q != 0 && this.preus[i].info) {
          this.infoPreu();
        }
      }

      this.entradesAnt = [...this.entrades];
    },
  },
  methods: {
    repartirEntrades() {
      if (this.totalEntrades() != this.numButaques) {
        var dif =
          this.numButaques -
          (this.totalEntrades() - (parseInt(this.entrades[0]) || 0));

        if (dif > 0) this.entrades[0] = dif.toString();
        else this.entrades[0] = "";

        // Per tal que s'actualitzi la pantalla
        this.entrades = [...this.entrades];
      }
    },
    totalEntrades() {
      var total = 0;

      for (var i = 0; i < this.preus.length; i++) {
        total += parseInt(this.entrades[i]) || 0;
        if ((parseInt(this.entrades[i]) || 0) <= 0) {
          this.entrades[i] = "";
        }
      }

      return total + this.vals.length;
    },
    infoFidelity() {
      if (this.$i18n.locale == "ca") {
        return sessionStorage.getItem("webtpv21_urlInfoFidelityCat");
      } else {
        return sessionStorage.getItem("webtpv21_urlInfoFidelityCas");
      }
    },
    infoPreu() {
      this.$alert.show({
        title: "",
        text: this.$t("INFOPREU"),
      });
    },
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    anterior() {
      window.location.href = sessionStorage.getItem("webtpv21_URLAnterior");
    },
    comprovarTargeta() {
      axios
        .post(api.URL() + "/api/v1/targeta", {
          token: sessionStorage.getItem("webtpv21_token"),
          targeta: this.nTargeta,
          contrasenya: this.contrasenya,
        })
        .then((response) => {
          // TODO
          if (this.guardarDadesTargeta) {
            localStorage.setItem("webtpv21_targeta", this.nTargeta);
            localStorage.setItem("webtpv21_password", this.contrasenya);
          } else {
            localStorage.removeItem("webtpv21_targeta");
            localStorage.removeItem("webtpv21_password");
          }
          var maxEntrades = response.data.maxEntrades;

          // Avisar si la targeta es nomes acumula punts
          if (response.data.nomesPunts) {
            this.$alert.show({
              title: "",
              text: this.$t("TARGETANOMESPUNTS"),
            });
          }

          this.dlgTargeta2 = false;
          // Tornar a carregar preus per tal que agafi el preu de targeta de fidelitzacio
          axios
            .post(api.URL() + "/api/v1/preus", {
              token: sessionStorage.getItem("webtpv21_token"),
            })
            .then((response) => {
              if (response.data.preuTargeta == true) {
                this.preus = response.data.preus;
                // Repartir entrades
                for (var i = 0; i < this.preus.length; i++) {
                  this.entrades[i] = "";
                }
                if (this.numButaques > maxEntrades) {
                  this.entrades[this.preus.length - 1] = maxEntrades;
                  this.entrades[0] = this.numButaques - maxEntrades;
                } else {
                  this.entrades[this.entrades.length - 1] = this.numButaques;
                }
              }
            })
            .catch((error) => {
              var msg;
              var onClose = {};
              switch (error.response.data) {
                case "TOKENCADUCATOINVALID":
                  msg = this.$t("TOKENCADUCATOINVALID");
                  onClose = () => {
                    this.adeu();
                  };
                  break;
                default:
                  msg = this.$t("ERRORINTERN");
                  onClose = () => {
                    this.adeu();
                  };
              }

              this.$alert.show({ title: "", text: msg, onClose: onClose });
            });
        })
        .catch((error) => {
          var msg = "";

          switch (error.response.data) {
            case "NOTARGETAVIP":
              msg = this.$t("NOTARGETAVIP");
              break;
            case "NOAQUESTTIPUSTARGETA":
              msg = this.$t("NOAQUESTTIPUSTARGETA");
              break;
            default:
              msg = this.$t("TARGETAINCORRECTA");
          }

          this.$alert.show({
            title: "",
            text: msg,
          });
        });
    },
    comprovarTargetaCli(cli) {
      this.nTargeta = "";

      axios
        .post(api.URL() + "/api/v1/targetacli", {
          token: sessionStorage.getItem("webtpv21_token"),
          cli: cli,
        })
        .then((response) => {
          this.nTargeta = response.data.targeta;
          if (this.guardarDadesTargeta) {
            localStorage.setItem("webtpv21_targeta", response.data.targeta);
            localStorage.setItem("webtpv21_password", response.data.contrasenya);
          } else {
            localStorage.removeItem("webtpv21_targeta");
            localStorage.removeItem("webtpv21_password");
          }

          // Avisar si la targeta es nomes acumula punts
          if (response.data.nomesPunts) {
            this.$alert.show({
              title: "",
              text: this.$t("TARGETANOMESPUNTS"),
            });
          }
          // Carregar preus per tal que agafi el preu de targeta de fidelitzacio
          this.carregarPreus();

          // Tornar a carregar preus per tal que agafi el preu de targeta de fidelitzacio
          // axios
          //   .post(api.URL() + "/api/v1/preus", {
          //     token: sessionStorage.getItem("webtpv21_token"),
          //   })
          //   .then((response) => {
          //     if (response.data.preuTargeta == true) {
          //       this.preus = response.data.preus;
          //       // Repartir entrades
          //       for (var i = 0; i < this.preus.length; i++) {
          //         this.entrades[i] = "";
          //       }
          //       if (this.numButaques > maxEntrades) {
          //         this.entrades[this.preus.length - 1] = maxEntrades;
          //         this.entrades[0] = this.numButaques - maxEntrades;
          //       } else {
          //         this.entrades[this.entrades.length - 1] = this.numButaques;
          //       }
          //     }
          //   })
          //   .catch((error) => {
          //     var msg;
          //     var onClose = {};
          //     switch (error.response.data) {
          //       case "TOKENCADUCATOINVALID":
          //         msg = this.$t("TOKENCADUCATOINVALID");
          //         onClose = () => {
          //           this.adeu();
          //         };
          //         break;
          //       default:
          //         msg = this.$t("ERRORINTERN");
          //         onClose = () => {
          //           this.adeu();
          //         };
          //     }

          //     this.$alert.show({ title: "", text: msg, onClose: onClose });
          //   });

          return true;
        })
        .catch((error) => {
          var msg = "";

          switch (error.response.data) {
            case "NOTARGETAVIP":
              msg = this.$t("NOTARGETAVIP");
              break;
            case "NOAQUESTTIPUSTARGETA":
              msg = this.$t("NOAQUESTTIPUSTARGETA");
              break;
            default:
              msg = this.$t("TARGETAINCORRECTA");
          }

          this.$alert.show({
            title: "",
            text: msg,
          });
        });

      return false;
    },
    format(num) {
      return FN.format(num);
    },
    subtotal() {
      var st = 0.0;

      this.entrades.forEach((item, index) => {
        if (this.entrades[index]) {
          st += this.preus[index].preu * this.entrades[index];
        }
      });

      return this.format(st);
    },
    total(preu, entrades) {
      if (!isNaN(entrades) && entrades != 0) {
        return this.format(preu * entrades);
      } else {
        return "0,00 €";
      }
    },
    validarVal() {
      this.btnValidarVal = false;
      var vegadesEnLlista = 0;

      this.vals.forEach((vl) => {
        if (this.val == vl.val) {
          vegadesEnLlista++;
        }
      });

      axios
        .post(api.URL() + "/api/v1/validarval", {
          token: sessionStorage.getItem("webtpv21_token"),
          val: this.val,
          vegadesEnLlista: vegadesEnLlista,
        })
        .then((response) => {
          this.vals.push({
            val: this.val,
            msg:
              response.data.arg2 != "" && response.data.arg2 != "0"
                ? this.val +
                " " +
                this.$t("UTILITZATS") +
                " " +
                response.data.arg1 +
                " de " +
                response.data.arg2
                : this.val,
          });
          sessionStorage.setItem("webtpv21_vals", JSON.stringify(this.vals));
          this.val = "";
          this.btnValidarVal = true;
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "NOCONNBDVALS":
              msg = this.$t("NOCONNBDVALS");
              break;
            case "VALBAR":
              msg = this.$t("VALBAR");
              break;
            case "NOVALSCAMPANYA":
              msg = this.$t("NOVALSCAMPANYA");
              break;
            case "VALNOVALID":
              msg = this.$t("VALNOVALID");
              break;
            case "VALUSAT":
              msg = this.$t("VALUSAT");
              break;
            case "VALNOASSOCIATACAMPANYA":
              msg = this.$t("VALNOASSOCIATACAMPANYA");
              break;
            case "VALUNAVALIDACIO":
              msg = this.$t("VALUNAVALIDACIO");
              break;
            case "VALMAXIMVALIDACIONS":
              msg =
                this.$t("VALMAXIMVALIDACIONS1") +
                error.response.data.arg1 +
                this.$t("VALMAXIMVALIDACIONS2");
              break;
            case "VALNOPERMETESDEVENIMENTS":
              msg = this.$t("VALNOPERMETESDEVENIMENTS");
              break;
            case "VALNOPERMET3D":
              msg = this.$t("VALNOPERMET3D");
              break;
            case "VALNOVALIDPERLAPELICULA":
              msg =
                this.$t("VALNOVALIDPERLAPELICULA") + error.response.data.arg1;
              break;
            case "VALNOVALIDFESTIUS":
              msg = this.$t("VALNOVALIDFESTIUS");
              break;
            case "VALCADUCAT":
              msg = this.$t("VALCADUCAT") + error.response.data.arg1;
              break;
            case "VALENCARANOVALID":
              msg = this.$t("VALENCARANOVALID") + error.response.data.arg1;
              break;
            case "VALNOINTERNET":
              msg = this.$t("VALNOINTERNET");
              break;
            case "VALNOSERVEIX":
              msg = this.$t("VALNOSERVEIX");
              break;
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.btnValidarVal = true;
          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
    esborrarVal(index) {
      this.vals.splice(index, 1);
      sessionStorage.setItem("webtpv21_vals", JSON.stringify(this.vals));
    },
    seguent() {
      var validar = [];

      console.log(this.entrades);

      if (this.preus != null)
        this.entrades.forEach((item, index) => {
          if (this.entrades[index]) {
            validar.push({
              id: this.preus[index].id,
              quantitat: parseInt(this.entrades[index]),
            });
          }
        });

      var llistaVals = [];
      this.vals.forEach((item) => {
        llistaVals.push(item.val);
      });

      axios
        .post(api.URL() + "/api/v1/validar", {
          token: sessionStorage.getItem("webtpv21_token"),
          entrades: validar,
          vals: llistaVals,
        })
        .then(() => {
          sessionStorage.setItem(
            "webtpv21_entrades",
            JSON.stringify(this.entrades)
          );

          if (sessionStorage.getItem("webtpv21_bar") == "S") {
            if (sessionStorage.getItem("webtpv21_botoneraReduida") == "") {
              this.$router.push("/Bar");
            } else {
              this.$router.push("/BarUCC");
            }
          } else this.$router.push("/Resum");
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "POQUESENTRADES":
              msg =
                this.$t("POQUESENTRADES1") +
                error.response.data.arg1 +
                this.$t("POQUESENTRADES2");
              break;
            case "MASSAENTRADES":
              msg = this.$t("MASSAENTRADES") + error.response.data.arg1;
              break;
            case "LIMITTARGETAOPCIONALPERPELICULA":
              msg =
                this.$t("LIMITTARGETAOPCIONALPERPELICULA1") +
                error.response.data.arg1 +
                this.$t("LIMITTARGETAOPCIONALPERPELICULA2");
              break;
            case "LIMITTARGETAOPCIONALPERVENDA":
              msg = this.tipusWeb != "O"
                ? this.$t("LIMITTARGETAOPCIONALPERVENDA31", {
                  n: error.response.data.arg1
                })
                : this.$t("LIMITTARGETAOPCIONALPERVENDA3", {
                  n: error.response.data.arg1
                })
              break;
            case "HADESERPARELL":
              msg =
                this.$t("HADESERPARELL1") +
                error.response.data.arg1 +
                this.$t("HADESERPARELL2");
              break;
            case "HADESERMULTIPLEDE3":
              msg =
                this.$t("HADESERMULTIPLEDE31") +
                error.response.data.arg1 +
                this.$t("HADESERMULTIPLEDE32");
              break;
            case "HADESERMULTIPLEDE4":
              msg =
                this.$t("HADESERMULTIPLEDE41") +
                error.response.data.arg1 +
                this.$t("HADESERMULTIPLEDE42");
              break;
            case "NOESPERMETPREU":
              msg =
                this.$t("NOESPERMETPREU1") +
                error.response.data.arg1 +
                this.$t("NOESPERMETPREU2");
              break;
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
    init() {
      this.btnValidarVal = true;

      addEventListener("resize", () => {
        this.mobil = innerWidth <= 1300;
      });

      // Veure si va en mode app
      let app = sessionStorage.getItem("webtpv21_app");
      let cli = sessionStorage.getItem("webtpv21_cli");
      // app to UpperCase
      if (app != null) {
        app = app.toUpperCase();
      }

      this.btnValidarVal = true;
      this.numButaques = sessionStorage.getItem("webtpv21_numButaques");

      // Dades targeta fidelitzacio
      this.fidelity = sessionStorage.getItem("webtpv21_fidelity");
      var targeta, password;
      targeta = localStorage.getItem("webtpv21_targeta");
      password = localStorage.getItem("webtpv21_password");
      //TODO
      if (targeta != null && app != "S") {
        this.nTargeta = targeta;
        this.guardarDadesTargeta = true;
      }

      if (password != null && app != "S") {
        this.contrasenya = password;
        this.guardarDadesTargeta = true;
      }

      // Barra de progres
      if (sessionStorage.getItem("webtpv21_bar") == "S")
        this.$emit("progresevent", 40);
      else this.$emit("progresevent", 45);

      var calCarregarPreus = true;
      var json = sessionStorage.getItem("webtpv21_entrades");
      if (json != null) {
        this.dlgTargeta1 = false;
        this.entrades = JSON.parse(json);
      } else {

        if (this.fidelity == "S") {
          if (app == "S" && cli != null && cli != "" && cli != "0") {
            this.comprovarTargetaCli(cli);
            calCarregarPreus = false;
          } else {
            if (app != "S")
              this.dlgTargeta1 = true;
          }
        }
      }

      if (calCarregarPreus) {
        this.carregarPreus();
      }

      // Scroll to top
      window.scrollTo(0, 0);

      // Cridar repartirEntrades cada 1 segon
      setInterval(() => {
        this.repartirEntrades();
      }, 1000);
    },

    carregarPreus() {
      axios
        .post(api.URL() + "/api/v1/preus", {
          token: sessionStorage.getItem("webtpv21_token"),
        })
        .then((response) => {
          this.preus = response.data.preus;
          let maxEntrades = response.data.maxEntrades;

          if (response.data.preuTargeta == true) {
            this.preus = response.data.preus;
            // Repartir entrades
            for (var i = 0; i < this.preus.length; i++) {
              this.entrades[i] = "";
            }
            if (this.numButaques > maxEntrades) {
              this.entrades[this.preus.length - 1] = maxEntrades;
              this.entrades[0] = this.numButaques - maxEntrades;
            } else {
              this.entrades[this.entrades.length - 1] = this.numButaques;
            }
          } else {
            this.entrades[0] = this.numButaques;
          }


          // Facebook pixel
          var pixel = sessionStorage.getItem("webtpv21_faceBookPixel");
          if (pixel != null && pixel != "") {
            console.log("Generant 'AddToCard' Facebook pixel...");

            var fbq = window.fbq || function () { };
            fbq("track", "AddToCard");
          }

          // Google site tag
          if (sessionStorage.getItem("webtpv21_googleSiteTag")) {
            var gst = JSON.parse(
              sessionStorage.getItem("webtpv21_googleSiteTag")
            );
            if (gst != null && gst.pixelGoogleAddToCart != "") {
              var gtag = window.gtag || function () { };
              gtag("event", "conversion", {
                send_to: gst.pixelGoogleAddToCart,
              });
            }
          }


          // Vals
          var c = sessionStorage.getItem("webtpv21_vals");
          if (c != null && c != "") {
            this.vals = JSON.parse(c);
            this.repartirEntrades();
          }
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
  },
  activated() {
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.dlgBtnPC {
  border-radius: 10px;
  width: 200px;
  text-transform: none;
}

.dlgBtn {
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  width: 300px;
  text-transform: none;
}

.dlgInput {
  margin: 0 auto;
  display: block;
  width: 300px;
}

.black--text /deep/ label {
  color: black;
}

button.v-icon.mdi {
  color: #e31d1a;
}
</style>
