 <template>
  <v-col>
    <div class="card">
      <v-container>
        <v-row justify="center">
          <v-col align="center">
            <v-img
              class=""
              width="120px"
              :src="'data:image/jpeg;base64,' + imatge"
              :alt="descripcio"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <div
              class="v-label font-weight-bold text-subtitle-2 descripcio"
              v-html="descripcio"
            ></div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ["descripcio", "imatge"],
};
</script>

<style scoped>
.card {
  border-style: solid;
  border-radius: 10px;
  border-width: 0px;
}

.card:active {
  background: #e31d1a;
}

.descripcio {
  text-align: center;
}
</style>
