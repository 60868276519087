<template>
  <v-app id="app">
    <v-main>
      <v-container>
        <!-- versio pc -->
        <div v-if="!mobil">
          <v-row justify="center">
            <v-col sm="11" xl="10">
              <v-card elevation="0">
                <v-card-title id="capApp" style="background-color: #eeeeee">
                  <v-row class="mt-1 mb-1 ml-6" align="baseline">
                    <v-img style="top: 5px" max-width="150" :src="'data:image/png;base64,' + logo"
                      @click="adeu()" aria-label="Logo"></v-img>
                    <div id="nom-cinema" class="ml-2 font-weight-bold">
                      {{ nomCinema }}
                    </div>
                    <v-spacer />
                    <v-select v-if="mostrarIdioma != 'S'" v-model="idioma" :items="idiomes" item-text="lang"
                      item-value="key" light background-color="grey lighten-3" class="mr-10 idioma"
                      style="max-width: 150px" aria-label="Selección idioma"></v-select>
                  </v-row>
                </v-card-title>

                <v-card-text id="passos">
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12">
                      <header>
                        <v-row v-if="bar">
                          <v-col align="left" style="padding-right: 0px">
                            <pas numero="1" :nom="$t('BUTAQUES')" />
                          </v-col>
                          <v-col align="center" style="padding-right: 0px; padding-left: 0px">
                            <pas numero="2" :nom="$t('ENTRADES')" />
                          </v-col>
                          <v-col align="center" style="padding-right: 0px; padding-left: 0px">
                            <pas numero="3" :nom="$t('BAR')" />
                          </v-col>
                          <v-col align="center" style="padding-right: 0px; padding-left: 0px">
                            <pas numero="4" :nom="$t('RESUM')" />
                          </v-col>
                          <v-col align="right" style="padding-left: 0px">
                            <pas numero="5" :nom="$t('PAGAMENT')" />
                          </v-col>
                        </v-row>

                        <v-row v-else>
                          <v-col align="left" style="padding-right: 0px">
                            <pas numero="1" :nom="$t('BUTAQUES')" />
                          </v-col>
                          <v-col align="center" style="padding-right: 0px; padding-left: 0px">
                            <pas numero="2" :nom="$t('ENTRADES')" />
                          </v-col>
                          <v-col align="center" style="padding-right: 0px; padding-left: 0px">
                            <pas numero="3" :nom="$t('RESUM')" />
                          </v-col>
                          <v-col align="right" style="padding-left: 0px">
                            <pas numero="4" :nom="$t('PAGAMENT')" />
                          </v-col>
                        </v-row>
                        <v-progress-linear class="mt-4" v-model="progres" color="#e31d1a"></v-progress-linear>
                      </header>
                      <main>
                        <div class="mt-4" style="min-height: calc(100vh - 205px)">
                          <keep-alive>
                            <router-view @progresevent="progresVal" />
                          </keep-alive>
                        </div>
                      </main>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <!-- versio mobil -->
        <div v-else>
          <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4">
              <v-card>
                <v-card-title id="capApp" style="background-color: #d9d9d9">
                  <header>
                    <v-row class="mt-1 mb-1" align="baseline" justify="center">
                      <v-img style="top: 5px" max-width="150" :src="'data:image/png;base64,' + logo" alt="logo OCINE"
                        @click="adeu()"></v-img>
                      <div id="nom-cinema" class="text-h6 ml-2 font-weight-bold">
                        {{ nomCinema }}
                      </div>
                    </v-row>
                  </header>
                </v-card-title>
                <header>
                  <v-progress-linear id="progres" v-model="progres" color="#e31d1a"></v-progress-linear>
                </header>
                <v-card-text>
                  <div class="mt-4" style="min-height: calc(100vh - 205px)">
                    <keep-alive>
                      <main>
                        <router-view @progresevent="progresVal" />
                      </main>
                    </keep-alive>
                  </div>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
    <footer>
      <v-footer app style="font-size: 10px">(@) SystemBCN 2021 - Ver.: {{ versio }}</v-footer>
    </footer>
    <alert />
  </v-app>
</template>

<script>
import axios from "axios";
import api from "./api.js";
import Pas from "./components/Pas.vue";
import { ebus } from "./main.js";

export default {
  components: {
    Pas,
  },
  data() {
    return {
      // IMPORTANT! Cal igualar-la a la del API al go
      versio: "3.9.65_20240902_1218",
      mobil: window.innerWidth <= 1300,
      progres: 40,
      idiomes: [
        { key: "es", lang: "Castellano" },
        { key: "ca", lang: "Català" },
      ],
      idioma: "ca",
      nomCinema: "",
      bar: true,
      mostrarIdioma: localStorage.getItem("webtpv21_mostrarIdioma"),
      logo: null,
    };
  },
  methods: {
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    progresVal(val) {
      this.progres = val;
    },
    addFacebookPixel(pixel) {
      console.log("addFacebookPixel", pixel);
      const script = document.createElement("script");
      script.text = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq("init", "${pixel}");
      `;
      document.head.appendChild(script);
    },
    addFacebookPixelDomain(domini) {
      console.log("addFacebookPixelDomain", domini);
      const meta = document.createElement("meta");
      meta.name = "facebook-domain-verification";
      meta.content = domini;
      document.head.appendChild(meta);
    },
    addGoogleSiteTag(tag) {
      const script = document.createElement("script");
      script.async = true;
      script.src =
        "https://www.googletagmanager.com/gtag/js?id=" + tag.pixelGoogle;
      document.head.appendChild(script);

      const script1 = document.createElement("script");
      script1.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${tag.pixelGoogle}');
      `;
      document.head.appendChild(script1);
    },
    addGoogleSiteTagDomain(domini) {
      const script = document.createElement("script");

      if (domini != null && domini != "") {
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + domini;
      }
      document.head.appendChild(script);

      const script1 = document.createElement("script");
      script1.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${domini}');
      `;
      document.head.appendChild(script1);
    },
  },
  watch: {
    idioma(val) {
      this.$i18n.locale = val;
      sessionStorage.setItem("webtpv21_idioma", val);
      ebus.$emit("idioma", val);
    },
  },

  activated() {
    this.mostrarIdioma = localStorage.getItem("webtpv21_mostrarIdioma");
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const butaques = urlParams.get("butaques");
    const plan = urlParams.get("plan");
    const nume = urlParams.get("nume");
    const urlconfirmation = urlParams.get("urlconfirmation");
    const urlinici = urlParams.get("urlinici");
    const idioma = urlParams.get("idioma");
    const deluxe = urlParams.get("deluxe");
    const app = urlParams.get("app");
    const cli = urlParams.get("c");

    sessionStorage.setItem("webtpv21_app", app);

    if (idioma == "CONS_CAT") {
      this.idioma = "ca";
    } else {
      this.idioma = "es";
    }
    this.$i18n.locale = this.idioma;
    sessionStorage.setItem("webtpv21_idioma", this.idioma);

    // Si no torna d'ecotel inicialitzar...
    if (window.location.href.indexOf("/Final") < 0) {
      // Per treure els parametres get de la URL sense recarregar la pagina
      // history.replaceState({}, null, "/");

      sessionStorage.removeItem("webtpv21_entrades");
      sessionStorage.removeItem("webtpv21_cistella");
      sessionStorage.removeItem("webtpv21_vals");
      sessionStorage.removeItem("webtpv21_valbar");
      sessionStorage.removeItem("webtpv21_tvalbar");
      sessionStorage.removeItem("webtpv21_dvalbar");

      sessionStorage.setItem("webtpv21_URLRetorn", urlinici);
      sessionStorage.setItem("webtpv21_URLAnterior", urlconfirmation);

      axios.get(api.URL() + "/api/v1/version").then((response) => {
        if (this.versio != response.data) window.location.reload(true);

        axios
          .post(api.URL() + "/api/v1/init", {
            idioma: this.$i18n.locale,
            plan: plan,
            butaques: butaques,
            nume: nume,
            urlconfirmation: urlconfirmation,
            urlinici: urlinici,
            deluxe: deluxe,
            app: app,
            cli: cli,
          })
          .then((response) => {
            sessionStorage.setItem("webtpv21_tipusWeb", response.data.tipusWeb);
            if (cli != null && cli != "")
              sessionStorage.setItem("webtpv21_cli", cli);

            if (response.data.faceBookPixel != "") {
              sessionStorage.setItem(
                "webtpv21_faceBookPixel",
                response.data.faceBookPixel
              );
              this.addFacebookPixel(response.data.faceBookPixel);
            } else {
              sessionStorage.removeItem("webtpv21_faceBookPixel");
            }

            if (response.data.faceBookPixelDomain != "") {
              sessionStorage.setItem(
                "webtpv21_faceBookPixelDomain",
                response.data.faceBookPixelDomain
              );
              this.addFacebookPixelDomain(response.data.faceBookPixelDomain);
            } else {
              sessionStorage.removeItem("webtpv21_faceBookPixelDomain");
            }

            if (response.data.googleSiteTag != null) {
              sessionStorage.setItem(
                "webtpv21_googleSiteTag",
                JSON.stringify(response.data.googleSiteTag)
              );
              this.addGoogleSiteTag(response.data.googleSiteTag, response.data.googleSiteTagDomain);

            } else {
              sessionStorage.removeItem("webtpv21_googleSiteTag");
            }

            if (response.data.googleSiteTagDomain != "") {
              sessionStorage.setItem(
                "webtpv21_googleSiteTagDomain",
                response.data.googleSiteTagDomain
              );
              this.addGoogleSiteTagDomain(response.data.googleSiteTagDomain);

            } else {
              sessionStorage.removeItem("webtpv21_googleSiteTagDomain");
            }

            sessionStorage.setItem(
              "webtpv21_hiHaVals",
              response.data.hiHaVals
            );

            sessionStorage.setItem(
              "webtpv21_hiHaValsBar",
              response.data.hiHaValsBar
            );

            sessionStorage.setItem("webtpv21_fidelity", response.data.fidelity);
            var nom = response.data.nomCinema.trim();

            if (nom.startsWith("OCINE")) {
              nom = nom.substring(5).trim();
            }

            sessionStorage.setItem("webtpv21_nomCinema", nom);
            this.nomCinema = nom;
            this.mostrarIdioma = response.data.mostrarIdioma;
            localStorage.setItem("webtpv21_mostrarIdioma", this.mostrarIdioma);
            console.log(this.mostrarIdioma);
            sessionStorage.setItem("webtpv21_token", response.data.token);
            sessionStorage.setItem(
              "webtpv21_numButaques",
              response.data.numButaques
            );
            // this.$router.push("/Preus");

            sessionStorage.setItem(
              "webtpv21_urlInfoFidelityCas",
              response.data.urlInfoFidelityCas
            );

            sessionStorage.setItem(
              "webtpv21_urlInfoFidelityCat",
              response.data.urlInfoFidelityCat
            );

            sessionStorage.setItem(
              "webtpv21_botoneraReduida",
              response.data.botoneraReduida
            );

            sessionStorage.setItem("webtpv21_token", response.data.token);
            sessionStorage.setItem("webtpv21_bar", response.data.bar);
            this.bar = response.data.bar == "S";
            if (this.bar) this.progres = 40;
            else this.progres = 45;
            sessionStorage.setItem(
              "webtpv21_linkCanviPinTargeta",
              response.data.linkCanviPinTargeta
            );

            var vals = [];
            response.data.valsClient.forEach((item) => {
              vals.push({
                val: item.val,
                msg:
                  item.maxim != "" && item.maxim != "0"
                    ? item.val +
                    " " +
                    this.$t("UTILITZATS") +
                    " " +
                    item.gastats +
                    " de " +
                    item.maxim
                    : item.val,
              });
            });

            sessionStorage.setItem("webtpv21_vals", JSON.stringify(vals));
            sessionStorage.setItem("webtpv21_nomesBar", response.data.nomesBar);

            if (response.data.nomesBar == "S")
              this.$router.push("/Bar");
            else
              this.$router.push("/Preus");

          })
          .catch((error) => {
            this.nomCinema = error.response.data.nomCinema;
            var onClose = () => {
              this.adeu();
            };

            var msg;
            switch (error.response.data.error) {
              case "VENTAINTERNETBLOQUEJADA":
                msg = this.$t("VENTAINTERNETBLOQUEJADA");
                break;
              case "ENCARANOESPOTCOMPRAR":
                msg =
                  this.$t("ENCARANOESPOTCOMPRAR") + error.response.data.arg1;
                break;
              case "MASSATARD":
                msg =
                  this.$t("MASSATARD1") +
                  error.response.data.arg1 +
                  this.$t("MASSATARD2");
                break;
              case "NOBUTAQUES":
                msg = this.$t("NOBUTAQUES");
                break;
              case "NOMESBUTAQUESVIP":
                msg =
                  this.$t("NOMESBUTAQUESVIP1") +
                  error.response.data.arg1 +
                  this.$t("NOMESBUTAQUESVIP2");
                break;
              case "NOHIHALLOC":
                msg = this.$t("NOHIHALLOC");
                break;
              case "NOHIHAPROULLOC":
                msg =
                  this.$t("NOHIHAPROULLOC1") +
                  error.response.data.arg1 +
                  this.$t("NOHIHAPROULLOC2");
                break;
              case "TOKENCADUCATOINVALID":
                msg = this.$t("TOKENCADUCATOINVALID");
                break;
              default:
                msg = this.$t("ERRORINTERN");
            }

            this.$alert.show({ title: "", text: msg, onClose: onClose });
          });
      });
    } else {
      // Nom cinema
      this.nomCinema = sessionStorage.getItem("webtpv21_nomCinema");

      // Carregar pixel de facebook
      var faceBookPixel = sessionStorage.getItem("webtpv21_faceBookPixel");
      var faceBookPixelDomain = sessionStorage.getItem("webtpv21_faceBookPixelDomain");
      if (faceBookPixel != null)
        this.addFacebookPixel(faceBookPixel);
      if (faceBookPixelDomain != null)
        this.addFacebookPixelDomain(faceBookPixelDomain);

      // Carregar google site tag
      var googleSiteTag = sessionStorage.getItem("webtpv21_googleSiteTag");
      var googleSiteTagDomain = sessionStorage.getItem("webtpv21_googleSiteTagDomain");
      if (googleSiteTag != null)
        this.addGoogleSiteTag(JSON.parse(googleSiteTag));
      if (googleSiteTagDomain != null)
        this.addGoogleSiteTagDomain(googleSiteTagDomain);
    }
  },
  created() {
    this.mostrarIdioma = localStorage.getItem("webtpv21_mostrarIdioma");

    addEventListener("resize", () => {
      this.mobil = innerWidth <= 1300;
    });

    // Carregar CSS (style.css)
    axios.get(api.URL() + "/style").then((response) => {
      var styleElement = document.createElement("style");

      styleElement.appendChild(document.createTextNode(response.data));
      document.getElementsByTagName("head")[0].appendChild(styleElement);
    });
    // Carregar logo
    axios.get(api.URL() + "/logo").then((response) => {
      this.logo = response.data;
    });
  },
};
</script>

<style>
.v-progress-linear__background {
  opacity: 0 !important;
}

.v-btn__content {
  color: white !important;
}

div.v-input__slot {
  border-style: hidden;
}

#tornar.v-btn {
  background-color: #58564f !important;
}
</style>