/*jshint esversion: 6 */

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Alert from './plugins/alert';
import i18n from './i18n'

Vue.config.productionTip = false;
Vue.use(Alert);

export const ebus = new Vue();

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
