import { render, staticRenderFns } from "./BotoArticle.vue?vue&type=template&id=61273d42&scoped=true"
import script from "./BotoArticle.vue?vue&type=script&lang=js"
export * from "./BotoArticle.vue?vue&type=script&lang=js"
import style0 from "./BotoArticle.vue?vue&type=style&index=0&id=61273d42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61273d42",
  null
  
)

export default component.exports