/*jshint esversion: 6 */

function URL() {
  if (process.env.NODE_ENV == "development")
    return window.location.protocol + "//" + window.location.hostname + ":8080";

  if (window.location.port == "") {
    return window.location.protocol + "//" + window.location.hostname;
  }

  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port
  );
}

export default {
  URL,
};
