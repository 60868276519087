<template>
  <div class="black--text">
    <v-row justify="center">
      <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 boto-tornar" to="/Resum"
        aria-label="Volver">
        {{ $t("TORNAR") }}
      </v-btn>
    </v-row>
    <div v-html="contracte"></div>
    <v-row justify="center">
      <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 boto-tornar" to="/Resum"
        aria-label="Volver">
        {{ $t("TORNAR") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data() {
    return {
      contracte: "",
    };
  },
  methods: {
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    init() {
      axios
        .get(api.URL() + "/api/v1/contracte")
        .then((response) => {
          this.contracte = response.data.contracte;
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
  },
  activated() {
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style>
.boto-tornar {
  background-color: #58564f;
}
</style>
