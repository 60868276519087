<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-sheet class="alert">
      <v-main>
        <v-btn
          icon
          @click="close()"
          class="alertx"
          aria-label="Cerrar"
        >
          <v-icon id="alert-tancar" aria-label="Cerrar">mdi-close</v-icon>
        </v-btn>
        <div class="ml-4 mr-4 mt-6" style="color: white">
          <div class="text-h6 alert-text">{{ title }}</div>
          <div>{{ text }}</div>
        </div>
      </v-main>
    </v-sheet>
  </v-dialog>
</template>

<script>
import Alert from "./alert.js";

export default {
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
      onClose: {},
    };
  },
  methods: {
    show(params) {
      this.dialog = true;
      this.title = params.title;
      this.text = params.text;
      this.onClose = params.onClose;
    },
    close() {
      this.dialog = false;
      this.onClose();
    },
  },
  beforeMount() {
    Alert.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>

<style>

#alert-tancar.v-icon.mdi {
  color: #e31d1a;
}

.alert {
  background-color: black !important;
  border-radius: 8px;
}

.alertx {
  float: right; 
  background-color: black !important;
}

</style>