<template>
  <div class="pas">
    <div class="numero">{{ numero }}</div>
    <div class="nom">{{ nom }}</div>
  </div>
</template>

 <script>
export default {
  props: ["numero", "nom"],
};
</script>


<style >
.pas {
  color: #e31d1a;
  font-size: 14px;
  font-weight: bold;
  display: contents;
  padding: 0px;
}

.numero {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 4px;
  border: 2px solid #e31d1a;
  text-align: center;
  display: inline-block;
}

.nom {
  display: inline-block;
  margin-left: 10px;
}
</style>