/*jshint esversion: 6 */

import Vue from "vue";
import VueRouter from "vue-router";
import Error from "../views/Error.vue";
import Preus from "../views/Preus.vue";
import Resum from "../views/Resum.vue";
import Contracte from "../views/Contracte.vue";
import InfoComunicacions from "../views/InfoComunicacions.vue";
import Final from "../views/Final.vue";
import Bar from "../views/Bar.vue";
import BarUCC from "../views/BarUCC.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/Error",
    name: "Error",
    component: Error,
  },
  {
    path: "/Preus",
    name: "Preus",
    component: Preus,
  },
  {
    path: "/Resum",
    name: "Resum",
    component: Resum,
  },
  {
    path: "/Contracte",
    name: "Contracte",
    component: Contracte,
  },
  {
    path: "/InfoComunicacions",
    name: "InfoComunicacions",
    component: InfoComunicacions,
  },
  {
    path: "/Final",
    name: "Final",
    component: Final,
  },
  {
    path: "/Bar",
    name: "Bar",
    component: Bar,
  },
  {
    path: "/BarUCC",
    name: "BarUCC",
    component: BarUCC,
  }
];

const router = new VueRouter({
  routes,
});

export default router;