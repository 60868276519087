<template>
  <div class="black--text">
    <!-- versio pc -->
    <div v-if="!mobil">
      <div style="border: 1px solid #d9d9d9">
        <v-row class="mt-1 mb-3">
          <v-col cols="3">
            <div style="
                border-right: 1px solid #d9d9d9;
                padding: 12px;
                height: 100%;
              ">
              <div v-for="(item, index) in families" :key="index">
                <!-- Grups -->
                <v-row>
                  <v-col>
                    <boto-grup-PC @click.native="articles(item.artiGrup, index)" :descripcio="item.grupDescripcio"
                      :imatge="item.grupAspecte" :triat="index == grupTriat" />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col align="center" cols="6">
            <v-row>
              <v-col align="left" style="padding: 0px; overflow-y: auto">
                <div v-for="(item, index) in items" :key="index" style="display: inline-block">
                  <!-- articles -->
                  <div style="float: left">
                    <boto-article-PC @click.native="
                      entrarCistella({
                        id: item.artiArticle,
                        tipus: item.artiTipus,
                        imatge: item.artiAspecte,
                        nom: item.descripcio,
                        preu: item.preu,
                        dte: 0,
                      })
                      " :descripcio="item.descripcioBoto" :imatge="item.artiAspecte" />
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="!nomesBar" class="mt-12">
              <v-col align="center">
                <router-link to="/Resum" class="mt-14 mb-8 v-label link">
                  {{ $t("NOGRACIESPASSEGUENT") }}
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <div style="
                border-left: 1px solid #d9d9d9;
                padding: 12px;
                height: 100%;
              ">
              <v-container>
                <v-row justify="center" class="font-weight-bold mb-1" style="font-size: 12px; color: black">
                  {{ $t("RESUMCOMANDABAR") }}
                </v-row>
              </v-container>
              <div>
                <div style="height: 400px; overflow-y: auto; overflow-x: hidden">
                  <!-- <div
                    v-for="(item, index) in cistella"
                    :key="index"
                    style="font-size: 12px; color: black"
                  >
                    <v-row class="ml-0 mr-0">
                      <v-col cols="5" style="padding: 5px">
                        {{ item.nom }}
                      </v-col>
                      <v-col cols="2" align="right" style="padding: 5px">
                        {{ item.quantitat }}
                      </v-col>
                      <v-col cols="4" align="right" style="padding: 5px">
                        {{ format(item.preu) }}
                      </v-col>
                      <v-col cols="1" style="padding: 5px" align="left"
                        ><v-icon small color="#e31d1a" @click="esborrar(index)"
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </div> -->
                  <table style="padding-left: 5px; padding-right: 5px; width: 100%">
                    <tr v-for="(item, index) in cistella" :key="index" style="font-size: 12px; color: black">
                      <td style="width: 7%; vertical-align: top">
                        <div v-if="item.tipus == 'A11'">
                          <v-icon v-if="item.detall == true" small @click="item.detall = false">
                            mdi-minus-circle
                          </v-icon>
                          <v-icon v-else small @click="item.detall = true">
                            mdi-plus-circle
                          </v-icon>
                        </div>
                      </td>
                      <td style="width:51%; vertical-align: top;">
                        {{ item.nom }}
                        <span v-if="item.dte != 0">(-{{ item.dte }}%)</span>

                        <!-- Detall menu -->
                        <table v-if="item.tipus == 'A11' && item.detall == true" style="font-size: 10px; color: black">
                          <tr v-for="(nivell, index) in item.nivells" :key="index">
                            <td v-if="nivell.automatic == false">
                              {{ nivell.descripcio }}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="
                          width: 10%;
                          text-align: right;
                          vertical-align: top;
                        ">
                        {{ item.quantitat }}
                      </td>
                      <td style="
                          width: 25%;
                          text-align: right;
                          vertical-align: top;
                        ">
                        {{ format(item.preu) }}
                      </td>
                      <td align="right" style="width: 7%; vertical-align: top">
                        <v-icon small color="#e31d1a" @click="esborrar(index)" aria-label="Borrar">
                          mdi-close
                        </v-icon>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <table style="padding-left: 5px; padding-right: 5px; width: 100%">
                <!-- <tr>
                  <td style="width: 7%"></td>
                  <td style="width: 61%">{{ $t("SUBTOTAL") }}</td>
                  <td style="width: 25%" align="right">{{ subtotal }}</td>
                  <td style="width: 7%"></td>
                </tr> -->
                <!-- <tr>
                  <td></td>
                  <td>{{ nomIva }} {{ percentIva }}%</td>
                  <td align="right">{{ iva }}</td>
                  <td></td>
                </tr> -->
              </table>
              <table class="mt-2 mb-3" style="padding-left: 5px; padding-right: 5px; width: 100%">
                <tr>
                  <td style="width: 7%"></td>
                  <td class="font-weight-bold" style="width: 61%">
                    {{ $t("TOTAL", [nomIva]) }}
                  </td>
                  <td class="font-weight-bold" style="width: 25%" align="right">
                    {{ total }}
                  </td>
                  <td style="width: 7%"></td>
                </tr>
              </table>

              <v-row justify="center" class="mt-4" v-if="mostrarValsBar == 'S'">
                <v-btn depressed style="border-radius: 17px" min-width="160" color="#547604" @click="dialegVals()"
                  aria-label="Vales">
                  {{ $t("ENTRARVALS") }}
                </v-btn>
              </v-row>

              <v-row justify="center">
                <v-btn :disabled="btnSeguentDisabled" depressed style="border-radius: 17px" min-width="160"
                  color="#547604" class="mt-5 mb-10" @click="confirmar()" aria-label="Confirmar">
                  {{ $t("CONFIRMAR") }}
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <!-- Botons -->
        <v-row justify="center" class="mt-4">
          <v-btn depressed style="border-radius: 17px" min-width="150" color="#58564f" class="mt-10 mb-10 mr-2"
            @click="anterior()" aria-label="Volver">
            {{ $t("TORNAR") }}
          </v-btn>
          <v-btn :disabled="btnSeguentDisabled" depressed style="border-radius: 17px" min-width="150" color="#547604"
            class="ml-2 mt-10 mb-10" to="/Resum" aria-label="Siguiente">
            {{ $t("SEGUENT") }}
          </v-btn>
        </v-row>
      </div>
      <!-- Dialeg menus -->
      <v-dialog v-model="dlgMenu" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title>
            <v-spacer />
            <v-btn icon @click="dlgMenu = false" class="mr-n5 mt-n3" style="background-color: white !important"
              aria-label="Cerrar">
              <v-icon small color="black" arial-label="Cerrar">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <table class="black--text" style="width: 100%">
              <tr>
                <td style="vertical-align: top">
                  <v-img width="150px" :src="'data:image/jpeg;base64,' + imatgeMenu" :alt="descripcioMenu"></v-img>
                  <div class="v-label mt-3 mb-3 font-weight-bold" style="color: #e31d1a">
                    {{ format(preuMenu) }}
                  </div>
                  <div v-for="(item, index) in menu.nivells" :key="index" class="v-label black--text"
                    style="font-size: 10px">
                    {{ item.descripcio }}
                  </div>
                </td>
                <td style="vertical-align: top">
                  <table style="width: 100%">
                    <tr>
                      <td style="height: 100%; vertical-align: top">
                        <div class="v-label mb-5 font-weight-bold">
                          {{ descripcioMenu }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tr>
                            <td style="vertical-align: middle">
                              <div v-for="(item, index) in menu.nivells" :key="index" class="v-label black--text">
                                <table>
                                  <tr>
                                    <td>
                                      <v-select v-if="!item.automatic" v-model="selMenu[index]" :items="item.articles"
                                        item-text="descripcio" return-object outlined dense :label="item.descripcio"
                                        style="width: 320px" aria-label="Selección"></v-select>
                                    </td>
                                    <td style="
                                        width: 20px;
                                        padding-left: 10px;
                                        vertical-align: top;
                                      ">
                                      <!-- icona per esborrar la seleccio -->
                                      <v-icon v-if="!item.automatic &&
                                        !item.obligatori &&
                                        selMenu[index] != null
                                        " color="#e31d1a" @click="removeSelect(index)"
                                        arial-label="Borrar">mdi-close</v-icon>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style="vertical-align: middle">
                              <div class="mt-1">
                                <v-text-field :disabled="valMenu" v-model="quantitatMenu" color="black" outlined dense
                                  type="number" min="0" style="
                                    display: inline-block;
                                    margin-right: 10px;
                                    width: 50px;
                                  " aria-label="Cantidad">
                                </v-text-field>

                                <v-btn depressed color="#000000" class="ml-3" @click="entrarMenu()" style="
                                    border-radius: 10px;
                                    width: 245px;
                                    display: inline-block;
                                  " aria-label="Añadir">
                                  {{ $t("AFEGIR") }}
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dlgVals" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("TENSVAL") }}
              </v-col>
            </v-row>

            <v-row align="center" class="mt-n4">
              <v-col cols="8" class="mt-5">
                <v-text-field color="black" outlined dense autofocus v-model="valBar" aria-label="Vale">
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-0">
                <v-btn :disabled="btnValidarDisabled" depressed color="#000000" class="dlgBtnPC" style="width: 100%"
                  @click="validarVal()" aria-label="Validar">
                  {{ $t("VALIDAR") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="cancelValBar"
                  aria-label="Cancelar">
                  {{ $t("CANCELAR") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>
      <!-- Dialegs targetes -->
      <v-dialog v-model="dlgTargeta1" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ tipusWeb != "O" ? $t("TENSFIDELITY1") : $t("TENSFIDELITY") }}
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mt-3 mr-3" @click="
                  dlgTargeta1 = false;
                dlgTargeta2 = true;
                " aria-label="Sí">
                  {{ $t("SITINC") }}
                </v-btn>
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mt-3 ml-3"
                  @click="dlgTargeta1 = false; nTargeta = '';" aria-label="No">
                  {{ $t("NOTINC") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" class="mb-3">
                <a :href="infoFidelity()" class="font-weight-bold black--text">
                  {{
                    tipusWeb != "O"
                    ? $t("COMOBTENIRFIDELITY1")
                    : $t("COMOBTENIRFIDELITY")
                  }}
                </a>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dlgTargeta2" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{
                  tipusWeb != "O"
                  ? $t("INGRESAFIDELITY1")
                  : $t("INGRESAFIDELITY")
                }}
              </v-col>
            </v-row>

            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-text-field color="black" outlined dense autofocus v-model="nTargeta" aria-label="Tarjeta">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("CONTRASENYA") }}
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-text-field color="black" outlined dense hide-details v-model="contrasenya"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass" aria-label="Contraseña">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n2">
              <v-col class="dlgInput mt-3">
                <v-checkbox class="black--text" v-model="guardarDadesTargeta" :label="$t('GUARDARDADESTARGETA')"
                  hide-details>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-n2 mb-4">
              <v-col class="dlgInput mt-3">
                <a v-if="LinkCanviPinTargeta != ''" :href="LinkCanviPinTargeta">{{ $t("RESTABLIRCONTRASENYA") }}</a>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 mr-3" @click="comprovarTargeta()"
                  aria-label="Aceptar">
                  {{ $t("ACCEPTAR") }}
                </v-btn>
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="
                  dlgTargeta2 = false;
                nTargeta = '';
                " aria-label="Cancelar">
                  {{ $t("NOGRACIES") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>
    </div>

    <!-- versio mobil -->
    <div v-else>
      <v-row justify="center" class="mb-7">
        <v-col cols="10">
          <div class="text-h6 font-weight-bold" style="text-align: center; color: #e31d1a">
            {{ $t("SELECCIONAPRODUCTESBAR") }}
          </div>
        </v-col>
        <v-col cols="2" align-self="center">
          <v-icon @click="drawer = true" color="#e31d1a" large aria-label="Carro compra">mdi-cart
          </v-icon>
          <v-badge color="black" offset-y="-19px" offset-x="14px" :content="nArticles" :value="nArticles != 0"></v-badge>
        </v-col>
      </v-row>

      <div v-for="(item, index) in productes" :key="index">
        <!-- Grups -->
        <v-container>
          <v-row v-if="mode == 'G'">
            <v-col>
              <boto-grup @click.native="articles(item.col1.artiGrup)" :descripcio="item.col1.grupDescripcio"
                :imatge="item.col1.grupAspecte" />
            </v-col>
            <v-col>
              <boto-grup v-if="item.col2" @click.native="articles(item.col2.artiGrup)"
                :descripcio="item.col2.grupDescripcio" :imatge="item.col2.grupAspecte" />
            </v-col>
          </v-row>

          <!-- Articles -->
          <v-row class="" v-else>
            <v-col class="">
              <boto-article @click.native="
                entrarCistella({
                  id: item.col1.artiArticle,
                  tipus: item.col1.artiTipus,
                  imatge: item.col1.artiAspecte,
                  nom: item.col1.descripcio,
                  preu: item.col1.preu,
                  dte: 0,
                })
                " :descripcio="item.col1.descripcioBoto" :imatge="item.col1.artiAspecte" />
            </v-col>
            <v-col v-if="item.col2 && item.col2.artiArticle">
              <boto-article @click.native="
                entrarCistella({
                  id: item.col2.artiArticle,
                  tipus: item.col2.artiTipus,
                  imatge: item.col2.artiAspecte,
                  nom: item.col2.descripcio,
                  preu: item.col2.preu,
                  dte: 0,
                })
                " v-if="item.col2" :descripcio="item.col2.descripcioBoto" :imatge="item.col2.artiAspecte" />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-if="mode == 'G' && !nomesBar">
        <v-row justify="center">
          <router-link to="/Resum" class="mt-14 mb-8 v-label">{{
            $t("NOGRACIESPASSEGUENT")
          }}</router-link>
        </v-row>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-btn depressed x-large style="border-radius: 17px" min-width="250" color="#547604" class="mt-10 mb-10"
            @click="grups()" aria-label="Volver">
            {{ $t("TORNARALBAR") }}
          </v-btn>
        </v-row>
      </div>

      <v-navigation-drawer v-model="drawer" absolute temporary color="black" width="490px">
        <v-container>
          <v-row justify="center" class="font-weight-bold mt-6 mb-6" style="font-size: 18px; color: #e31d1a">
            {{ $t("RESUMCOMANDABAR") }}
          </v-row>
        </v-container>
        <div style="min-height: calc(80vh - 205px)">
          <table style="padding-left: 20px; padding-right: 20px; width: 100%">
            <tr v-for="(item, index) in cistella" :key="index" class="v-label white--text">
              <td style="width: 7%; vertical-align: top">
                <div v-if="item.tipus == 'A11'">
                  <v-icon v-if="item.detall == true" small @click="item.detall = false">
                    mdi-minus-circle
                  </v-icon>
                  <v-icon v-else small @click="item.detall = true">
                    mdi-plus-circle
                  </v-icon>
                </div>
              </td>

              <td style="width: 56%; padding-bottom: 10px; font-size: 14px">
                {{ item.nom }}
                <span v-if="item.dte != 0">(-{{ item.dte }}%)</span>

                <!-- Detall menu -->
                <table v-if="item.tipus == 'A11' && item.detall == true" style="font-size: 12px; color: white">
                  <tr v-for="(nivell, index) in item.nivells" :key="index">
                    <td v-if="nivell.automatic == false" style="padding-top: 5px; padding-bottom: 5px">
                      {{ nivell.descripcio }}
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width: 10%; text-align: right; vertical-align: top">
                {{ item.quantitat }}
              </td>
              <td style="width: 20%; text-align: right; vertical-align: top">
                {{ format(item.preu) }}
              </td>
              <td align="right" style="width:7%; text-align: right; vertical-align: top">
                <v-icon small color="#e31d1a" @click="esborrar(index)" arial-label="Borrar">
                  mdi-close
                </v-icon>
              </td>
            </tr>
          </table>
        </div>
        <div class="v-label white--text" style="font-size: 14px">
          <v-container>
            <!-- <v-row class="mt-6">
              <v-col cols="3" md="4"></v-col>
              <v-col cols="5">{{ $t("SUBTOTAL") }} </v-col
              ><v-col cols="3" md="2" align="right">{{ subtotal }}</v-col>
              <v-col cols="1" />
            </v-row> -->
            <!-- <v-row class="mt-n3">
              <v-col cols="5" md="6"></v-col>
              <v-col cols="3">{{ nomIva }} {{ percentIva }}% </v-col
              ><v-col cols="3" md="2" align="right">{{ iva }}</v-col>
              <v-col cols="1" />
            </v-row> -->
            <v-row class="mt-n3">
              <v-col cols="3" md="4"></v-col>
              <v-col cols="5" md="4" class="white--text font-weight-bold">
                {{ $t("TOTAL", [nomIva]) }}</v-col><v-col cols="3" class="white--text font-weight-bold" align="right">{{
                  total }}</v-col>
              <v-col cols="1" /> </v-row></v-container>
        </div>
        <v-row justify="center">
          <v-btn :disabled="btnSeguentDisabled" depressed x-large style="border-radius: 17px" min-width="300"
            color="#547604" class="mt-10 mb-10" @click="confirmar()" aria-label="Confirmar">
            {{ $t("CONFIRMAR") }}
          </v-btn>
        </v-row>
      </v-navigation-drawer>

      <!-- Dialeg menus -->
      <v-dialog v-model="dlgMenu" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title>
            <v-spacer />
            <v-btn icon @click="dlgMenu = false" class="mr-n5 mt-n3" style="background-color: white !important"
              aria-label="Cerrar">
              <v-icon small color="black" arial-label="Cerrar">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="black--text v-label mb-5 font-weight-bold">
              {{ descripcioMenu }}
            </div>
            <table class="black--text mb-4" style="width: 100%">
              <tr>
                <td style="vertical-align: top">
                  <v-img width="150px" :src="'data:image/jpeg;base64,' + imatgeMenu" :alt="descripcioMenu"></v-img>
                </td>
                <td style="vertical-align: middle">
                  <div v-for="(item, index) in menu.nivells" :key="index" class="v-label black--text"
                    style="font-size: 12px">
                    {{ item.descripcio }}
                  </div>
                  <div class="v-label mt-3 mb-3 font-weight-bold" style="color: #e31d1a">
                    {{ format(preuMenu) }}
                  </div>
                </td>
              </tr>
            </table>

            <table style="width: 100%; table-layout: fixed">
              <tr v-for="(item, index) in menu.nivells" :key="index" class="v-label black--text">
                <td>
                  <v-select v-if="!item.automatic" v-model="selMenu[index]" :items="item.articles" item-text="descripcio"
                    return-object outlined dense :label="item.descripcio" style="max-width: 100%"
                    aria-label="Selección"></v-select>
                </td>
                <td style="width: 30px; padding-left: 10px; vertical-align: top">
                  <!-- icona per esborrar la seleccio -->
                  <v-icon v-if="!item.automatic" color="#e31d1a" @click="removeSelect(index)"
                    arial-label="Borrar">mdi-close</v-icon>
                </td>
              </tr>
            </table>
            <table style="width: 100%">
              <tr>
                <td style="vertical-align: middle">
                  <v-text-field :disabled="valMenu" v-model="quantitatMenu" color="black" hide-details outlined dense
                    type="number" min="0" style="
                      display: inline-block;
                      margin-right: 10px;
                      width: 50px;
                    " aria-label="Cantidad">
                  </v-text-field>
                </td>
                <td style="vertical-align: middle">
                  <v-btn depressed color="#000000" @click="entrarMenu()" style="border-radius: 10px; width: 100%"
                    aria-label="Añadir">
                    {{ $t("AFEGIR") }}
                  </v-btn>
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dlgVals" width="550">
        <v-card style="border-radius: 8px">
          <v-card-title />
          <v-card-text>
            <v-row>
              <v-col align="center" class="font-weight-bold v-label black--text">
                {{ $t("TENSVAL") }}
              </v-col>
            </v-row>

            <v-row align="center" class="mt-n4">
              <v-col cols="8" class="mt-5">
                <v-text-field color="black" outlined dense autofocus v-model="valBar" aria-label="Vale">
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-0">
                <v-btn :disabled="btnValidarDisabled" depressed color="#000000" class="dlgBtnPC" style="width: 100%"
                  @click="validarVal()" aria-label="Validar">
                  {{ $t("VALIDAR") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col align="center">
                <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="dlgVals = false"
                  aria-label="Cancelar">
                  {{ $t("CANCELAR") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </v-dialog>

      <!-- Dialegs targetes -->
      <v-dialog v-model="dlgTargeta1" width="400">
        <v-sheet color="white" style="border-radius: 8px">
          <v-main>
            <div class="mt-6">
              <h4 style="text-align: center">
                {{ tipusWeb != "O" ? $t("TENSFIDELITY1") : $t("TENSFIDELITY") }}
              </h4>
            </div>
            <v-row>
              <v-col align="center">
                <v-btn depressed min-width="300" color="#000000" class="dlgBtn mb-1 mt-3" @click="
                  dlgTargeta1 = false;
                dlgTargeta2 = true;
                " aria-label="Sí">
                  {{ $t("SITINC") }}
                </v-btn>

                <v-btn depressed min-width="300" color="#000000" class="dlgBtn mb-6 mt-3" @click="dlgTargeta1 = false; nTargeta = '';"
                  aria-label="No">
                  {{ $t("NOTINC") }}
                </v-btn>
              </v-col>
            </v-row>
            <div style="text-align: center">
              <a :href="infoFidelity()" class="font-weight-bold black--text">
                {{
                  tipusWeb != "O"
                  ? $t("COMOBTENIRFIDELITY1")
                  : $t("COMOBTENIRFIDELITY")
                }}
              </a>
            </div>
          </v-main>
        </v-sheet>
      </v-dialog>

      <v-dialog v-model="dlgTargeta2" width="500">
        <v-sheet color="white" style="border-radius: 8px">
          <v-main>
            <div class="mt-6 mb-2">
              <h4 style="text-align: center">
                {{
                  tipusWeb != "O"
                  ? $t("INGRESAFIDELITY1")
                  : $t("INGRESAFIDELITY")
                }}
              </h4>
            </div>
            <div class="dlgInput">
              <v-text-field color="black" outlined dense autofocus v-model="nTargeta" aria-label="Tarjeta">
              </v-text-field>
            </div>
            <div class="mt-0 mb-2">
              <h4 style="text-align: center">Contraseña</h4>
            </div>
            <div class="dlgInput">
              <v-text-field color="black" outlined dense hide-details autofocus v-model="contrasenya"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass" aria-label="Contraseña">
              </v-text-field>
            </div>
            <div class="dlgInput">
              <v-checkbox class="black--text" v-model="guardarDadesTargeta" :label="$t('GUARDARDADESTARGETA')">
              </v-checkbox>
            </div>
            <div class="dlgInput mb-4">
              <a v-if="LinkCanviPinTargeta != ''" :href="LinkCanviPinTargeta">{{
                $t("RESTABLIRCONTRASENYA")
              }}</a>
            </div>
            <div>
              <v-btn depressed color="#000000" class="dlgBtn mb-1" @click="
              dlgTargeta2 = false;
              nTargeta = '';
              " aria-label="Cancelar">
                No, gracias
              </v-btn>
            </div>
            <v-btn depressed color="#000000" class="dlgBtn mb-3 mt-3" @click="comprovarTargeta()" aria-label="Aceptar">
              Aceptar
            </v-btn>
          </v-main>
        </v-sheet>
      </v-dialog>

      <v-row justify="center" class="mt-4" v-if="mostrarVals == 'S' && mostrarValsBar == 'S'">
        <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="mt-5 mb-5"
          @click="dialegVals()" aria-label="Vales">
          {{ $t("ENTRARVALS") }}
        </v-btn>
      </v-row>
      <!-- Botons -->
      <v-row justify="center" class="mt-4">
        <v-btn depressed style="border-radius: 17px" min-width="150" color="#58564f" class="mt-10 mb-10 mr-2"
          @click="anterior()" aria-label="Volver">
          {{ $t("TORNAR") }}
        </v-btn>
        <v-btn :disabled="btnSeguentDisabled" depressed style="border-radius: 17px" min-width="150" color="#547604"
          class="ml-2 mt-10 mb-10" to="/Resum" aria-label="Siguiente">
          {{ $t("SEGUENT") }}
        </v-btn>
      </v-row>
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";
import BotoGrup from "../components/BotoGrup.vue";
import BotoGrupPC from "../components/BotoGrupPC.vue";
import BotoArticle from "../components/BotoArticle.vue";
import BotoArticlePC from "../components/BotoArticlePC.vue";
import { ebus } from "../main.js";

const FN = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

export default {
  components: {
    BotoGrup,
    BotoGrupPC,
    BotoArticle,
    BotoArticlePC,
    ConfirmDlg: () => import("../components/ConfirmDlg"),
  },
  data() {
    return {
      mobil: window.innerWidth <= 1300,
      drawer: false,
      families: [],
      items: [],
      productes: [],
      cistella: [],
      mode: "G",
      nArticles: 0,
      subtotal: "",
      iva: "",
      total: "",
      grupTriat: -1,
      grupActual: "",
      dlgMenu: false,
      imatgeMenu: null,
      descripcioMenu: "",
      preuMenu: "",
      quantitatMenu: 1,
      dteMenu: 0,
      valMenu: false,
      menu: {},
      selMenu: [],
      nomIva: "IVA",
      percentIva: 0,
      dlgVals: false,
      mostrarVals: sessionStorage.getItem("webtpv21_hiHaVals"),
      mostrarValsBar: sessionStorage.getItem("webtpv21_hiHaValsBar"),
      valBar: "",
      tvalBar: "",
      dvalBar: 0,
      btnValidarDisabled: false,
      dlgTargeta1: false,
      dlgTargeta2: false,
      nTargeta: "",
      guardarDadesTargeta: false,
      LinkCanviPinTargeta: sessionStorage.getItem(
        "webtpv21_linkCanviPinTargeta"
      ),
      fidelity: false,
      showPass: false,
      contrasenya: "",
      tipusWeb: sessionStorage.getItem("webtpv21_tipusWeb"),
      nomesBar: false,
      btnSeguentDisabled: true,
    };
  },
  methods: {
    anterior() {
      console.log("nomesBar: " + this.nomesBar);

      if (this.nomesBar) {
        window.location.href = sessionStorage.getItem("webtpv21_URLAnterior");
      } else {
        this.$router.push("/Preus");
      }
    },
    removeSelect(index) {
      this.selMenu[index] = undefined;
      this.$forceUpdate();
    },
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    format(n) {
      return FN.format(n);
    },
    grups() {
      this.mode = "G";
      this.productes = [];
      axios
        .post(api.URL() + "/api/v1/grups", {
          idioma: sessionStorage.getItem("webtpv21_idioma"),
          app: sessionStorage.getItem("webtpv21_app"),
        })
        .then((response) => {
          this.families = response.data.grups;
          this.nomIva = response.data.nomIva;
          this.percentIva = response.data.percentIva.toString();

          for (var n = 0; n < this.families.length; n += 2) {
            var item;

            if (n == this.families.length - 1) {
              item = { col1: this.families[n] };
            } else {
              item = { col1: this.families[n], col2: this.families[n + 1] };
            }
            this.productes.push(item);
          }

          if (!this.mobil && this.families.length > 0)
            this.articles(this.families[0].artiGrup, 0);
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
    // funcio per arrodonir a dos decimals
    arrodonir(n) {
      return Math.round(n * 100) / 100;
    },
    // funcio per aplicar un descompte
    descompte(n, d) {
      return n - this.arrodonir(n * (d / 100));
    },
    articles(id, index) {
      this.grupTriat = index;
      this.grupActual = id;
      this.mode = "A";
      this.productes = [];
      axios
        .post(api.URL() + "/api/v1/articles", {
          token: sessionStorage.getItem("webtpv21_token"),
          idioma: sessionStorage.getItem("webtpv21_idioma"),
          id: id,
        })
        .then((response) => {
          this.items = response.data.articles;

          for (var n = 0; n < this.items.length; n += 2) {
            var item;

            if (n == this.items.length - 1) {
              item = { col1: this.items[n] };
            } else {
              item = { col1: this.items[n], col2: this.items[n + 1] };
            }
            this.productes.push(item);
          }
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
    confirmar() {
      this.$router.push("/Resum");
    },
    entrarMenu() {
      var nivsSelec = [];
      var increment = 0.0;
      // treure decimals a la quantitat
      this.quantitatMenu = Math.trunc(this.quantitatMenu);
      // var nivells = 0;

      // this.menu.nivells.forEach((element) => {
      //   if (!element.automatic) ++nivells;
      // });

      // if (Object.keys(this.selMenu).length != nivells) {
      //   this.$alert.show({ title: "", text: this.$t("TRIAMENU") });
      //   return;
      // }

      // Comprovar que la quantitat > 0
      if (this.quantitatMenu <= 0) {
        this.$alert.show({ title: "", text: this.$t("QUANTITATNOVALIDA") });
        return;
      }

      // comprovar que tots els nivells, que son obligatoris, estan seleccionats
      for (var n = 0; n < this.menu.nivells.length; n++) {
        if (
          !this.menu.nivells[n].automatic &&
          this.menu.nivells[n].obligatori
        ) {
          if (this.selMenu[n] == undefined) {
            this.$alert.show({ title: "", text: this.$t("TRIAMENU") });
            return;
          }
        }
      }

      // Comprovar que hi ha almenys un nivell seleccionat
      if ( this.selMenu.length == 0) {
        this.$alert.show({ title: "", text: this.$t("TRIAMENUMINIM1") });
        return;
      }

      this.dlgMenu = false;

      // Entrar nivells automatics
      var self = this;
      this.menu.nivells.forEach(function (nv) {
        if (nv.automatic) {
          nv.articles.forEach(function (art) {
            var nivSelec = {
              idNivell: nv.id,
              automatic: nv.automatic,
              obligatori: nv.obligatori,
              idArticle: art.id,
              descripcio: art.descripcio,
            };

            if (self.dteMenu != 0) {
              increment += self.descompte(art.incrementPreu, self.dteMenu);
            } else {
              increment += art.incrementPreu;
            }

            nivsSelec.push(nivSelec);
          });
        }
      });

      // Entrar articles seleccionats en els nivells
      var menu = this.menu;
      this.selMenu.forEach(function (art, index) {
        if (art != undefined) {
          var nivSelec = {
            idNivell: menu.nivells[index].id,
            automatic: menu.nivells[index].automatic,
            obligatori: menu.nivells[index].obligatori,
            idArticle: art.id,
            descripcio: art.descripcio,
          };

          if (self.dteMenu != 0) {
            increment += self.descompte(art.incrementPreu, self.dteMenu);
          } else {
            increment += art.incrementPreu;
          }

          nivsSelec.push(nivSelec);
        }
      });

      var preu;
      if (this.dteMenu != 0) {
        preu = this.descompte(this.preuMenu, this.dteMenu);
      } else {
        preu = this.preuMenu;
      }

      var item = {
        id: this.menu.id,
        grup: this.grupActual,
        tipus: "A11",
        imatge: this.imatgeMenu,
        nom: this.descripcioMenu,
        preu: preu + increment,
        quantitat: this.quantitatMenu,
        nivells: nivsSelec,
        detall: false,
        dte: this.dteMenu,
        val: this.valMenu,
      };

      this.cistella.push(item);
      sessionStorage.setItem(
        "webtpv21_cistella",
        JSON.stringify(this.cistella)
      );

      this.actualitzaBadge();
    },
    entrarCistella(article) {
      // Article a la cistella
      // Si tvalBar == "T" , posar descompte
      if (this.tvalBar == "T") {
        article.dte = this.dvalBar;
        article.val = true;
      }
      article.grup = this.grupActual;
      // Mirar si es un menu
      if (article.tipus == "A11") {
        this.imatgeMenu = article.imatge;
        this.descripcioMenu = article.nom;
        this.preuMenu = article.preu;
        this.quantitatMenu = 1;
        this.dlgMenu = true;
        this.dteMenu = article.dte;
        this.valMenu = article.val;

        axios
          .post(api.URL() + "/api/v1/menu", {
            token: sessionStorage.getItem("webtpv21_token"),
            idioma: sessionStorage.getItem("webtpv21_idioma"),
            id: article.id,
          })
          .then((response) => {
            this.selMenu = [];
            this.menu = response.data.menu;
          })
          .catch((error) => {
            var msg;
            var onClose = {};
            switch (error.response.data.error) {
              case "TOKENCADUCATOINVALID":
                msg = this.$t("TOKENCADUCATOINVALID");
                onClose = () => {
                  this.adeu();
                };
                break;
              default:
                msg = this.$t("ERRORINTERN");
                onClose = () => {
                  this.adeu();
                };
            }

            this.$alert.show({ title: "", text: msg, onClose: onClose });
          });
      } else {
        // Aplicar descompte al preu
        if (article.dte != 0) {
          article.preu = this.descompte(article.preu, article.dte);
        }
        // Buscar si ja hi es
        var trobat = false;
        if (this.cistella != null)
          this.cistella.forEach(function (item) {
            if (article.id == item.id && item.val == false) {
              item.quantitat++;
              trobat = true;
            }
          });

        if (!trobat) {
          article.quantitat = 1;
          this.cistella.push(article);
        }
        sessionStorage.setItem(
          "webtpv21_cistella",
          JSON.stringify(this.cistella)
        );

        this.actualitzaBadge();
      }
    },
    esborrar(index) {
      // Si estem esborrant un article de val, esborrar el val
      if (this.cistella[index].val == true) {
        this.valBar = "";
        this.dvalBar = 0;
        this.tvalBar = "";

        sessionStorage.setItem("webtpv21_valbar", this.valBar);
        sessionStorage.setItem("webtpv21_tvalbar", this.tvalBar);
        sessionStorage.setItem("webtpv21_dvalbar", this.dvalBar.toString());
      }
      this.cistella.splice(index, 1);
      sessionStorage.setItem(
        "webtpv21_cistella",
        JSON.stringify(this.cistella)
      );
      this.actualitzaBadge();
    },
    actualitzaBadge() {
      var n = 0;
      this.cistella.forEach(function (item) {
        n += parseInt(item.quantitat);
      });

      this.nArticles = n;

      this.totals();
    },
    totals() {
      var s;
      var i;
      let t = 0;
      let c = 0;
      // var dte = this.dvalBar;
      var self = this;

      this.cistella.forEach(function (item) {
        // Si hi ha un val de descompte posar el descompte
        // if (self.tvalBar == "T" || (self.tvalBar == "B" && item.val == true))
        //  arr[index].dte = dte;
        // else arr[index].dte = 0;

        // var preu = item.preu - (item.preu * item.dte) / 100;

        t += item.quantitat * item.preu;
        ++c;
      });

      s = t / (1 + this.percentIva / 100);
      i = t - s;

      this.subtotal = FN.format(s);
      this.iva = FN.format(i);
      this.total = FN.format(t);

      if (this.nomesBar) {
        if (c == 0) this.btnSeguentDisabled = true;
        else this.btnSeguentDisabled = false;
      } else {
        this.btnSeguentDisabled = false;
      }

      sessionStorage.setItem(
        "webtpv21_cistella",
        JSON.stringify(self.cistella)
      );

      console.log(this.cistella);
    },

    async dialegVals() {
      var self = this;

      if (this.valBar != "") {
        if (
          await this.$refs.confirm.open(this.$t("VALS"), this.$t("JAHIHAVAL"))
        ) {
          if (self.tvalBar != "T") {
            // Esborrar l'article de val
            self.cistella.forEach(function (item, index) {
              if (item.val == true) {
                self.cistella.splice(index, 1);
              }
            });
          } else {
            // esborrar tota la cistella
            self.cistella = [];
          }

          self.valBar = "";
          self.tvalBar = "";
          self.dvalBar = 0;

          sessionStorage.setItem("webtpv21_valbar", self.valBar);
          sessionStorage.setItem("webtpv21_tvalbar", self.tvalBar);
          sessionStorage.setItem("webtpv21_dvalbar", self.dvalBar.toString());

          // Recalcular el total
          self.totals();
          sessionStorage.setItem(
            "webtpv21_cistella",
            JSON.stringify(self.cistella)
          );
          self.actualitzaBadge();

          self.dlgVals = true;
        }
      } else {
        self.dlgVals = true;
      }
    },
    // Esborrar article de la cistella si hi es
    esborrarArticle(id) {
      var self = this;
      var trobat = false;
      this.cistella.forEach(function (item, index) {
        if (item.id == id && trobat == false) {
          trobat = true;
          self.cistella.splice(index, 1);
          sessionStorage.setItem(
            "webtpv21_cistella",
            JSON.stringify(self.cistella)
          );
          self.actualitzaBadge();
        }
      });
    },
    cancelValBar() {
      if (this.tvalBar=="") {
        this.valBar="";
      }
      this.dlgVals = false
    },
    validarVal() {
      this.btnValidarDisabled = true;
      axios
        .post(api.URL() + "/api/v1/validarval", {
          token: sessionStorage.getItem("webtpv21_token"),
          val: this.valBar,
          tipus: "B",
          nomesBar: (this.nomesBar) ? "S" : "N",
        })
        .then((response) => {
          this.btnValidarDisabled = false;
          this.tvalBar = response.data.tipusVal;
          this.dvalBar = response.data.dte;
          var articleVal = response.data.article;

          sessionStorage.setItem("webtpv21_valbar", this.valBar);
          sessionStorage.setItem("webtpv21_tvalbar", this.tvalBar);
          sessionStorage.setItem("webtpv21_dvalbar", this.dvalBar.toString());

          if (this.tvalBar == "T") {
            // Aplicar descompte a tota la cistella
            var self = this;
            this.cistella.forEach(function (item, index, arr) {
              arr[index].dte = self.dvalBar;
              arr[index].preu = self.descompte(item.preu, self.dvalBar);
              arr[index].val = true;
            });
            // Recalcular el total
            this.totals();
            sessionStorage.setItem(
              "webtpv21_cistella",
              JSON.stringify(this.cistella)
            );
          } else if (this.tvalBar == "B") {
            // TODO Entrar l'article del val...

            axios
              .post(api.URL() + "/api/v1/article", {
                token: sessionStorage.getItem("webtpv21_token"),
                idioma: sessionStorage.getItem("webtpv21_idioma"),
                id: articleVal,
              })
              .then((response) => {
                var item = response.data.article;

                // Esborrar article de la cistella si hi es
                this.esborrarArticle(item.artiArticle);

                this.entrarCistella({
                  id: item.artiArticle,
                  tipus: item.artiTipus,
                  imatge: item.artiAspecte,
                  nom: item.descripcio,
                  preu: item.preu,
                  dte: this.dvalBar,
                  val: true,
                });
              })
              .catch(() => {
                // Si l'article no es valid, el val no entra
                this.valBar = "";
                this.tvalBar = "";
                this.dvalBar = 0;

                sessionStorage.setItem("webtpv21_valbar", this.valBar);
                sessionStorage.setItem("webtpv21_tvalbar", this.tvalBar);
                sessionStorage.setItem(
                  "webtpv21_dvalbar",
                  this.dvalBar.toString()
                );

                this.$alert.show({
                  title: "",
                  text: this.$t("VALARTICLEERR"),
                  onClose: {},
                });
              });
          }
          this.dlgVals = false;
        })
        .catch((error) => {
          this.btnValidarDisabled = false;
          this.valBar = "";
          this.dvalBar = 0;
          this.tvalBar = "";

          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "NOCONNBDVALS":
              msg = this.$t("NOCONNBDVALS");
              break;
            case "VALNOBAR":
              msg = this.$t("VALNOBAR");
              break;
            case "NOVALSCAMPANYA":
              msg = this.$t("NOVALSCAMPANYA");
              break;
            case "VALNOVALID":
              msg = this.$t("VALNOVALID");
              break;
            case "VALUSAT":
              msg = this.$t("VALUSAT");
              break;
            case "VALNOASSOCIATACAMPANYA":
              msg = this.$t("VALNOASSOCIATACAMPANYA");
              break;
            case "VALUNAVALIDACIO":
              msg = this.$t("VALUNAVALIDACIO");
              break;
            case "VALMAXIMVALIDACIONS":
              msg =
                this.$t("VALMAXIMVALIDACIONS1") +
                error.response.data.arg1 +
                this.$t("VALMAXIMVALIDACIONS2");
              break;
            case "VALNOPERMETESDEVENIMENTS":
              msg = this.$t("VALNOPERMETESDEVENIMENTS");
              break;
            case "VALNOPERMET3D":
              msg = this.$t("VALNOPERMET3D");
              break;
            case "VALNOVALIDPERLAPELICULA":
              msg =
                this.$t("VALNOVALIDPERLAPELICULA") + error.response.data.arg1;
              break;
            case "VALNOVALIDFESTIUS":
              msg = this.$t("VALNOVALIDFESTIUS");
              break;
            case "VALCADUCAT":
              msg = this.$t("VALCADUCAT") + error.response.data.arg1;
              break;
            case "VALENCARANOVALID":
              msg = this.$t("VALENCARANOVALID") + error.response.data.arg1;
              break;
            case "VALNOINTERNET":
              msg = this.$t("VALNOINTERNET");
              break;
            case "VALNOSERVEIX":
              msg = this.$t("VALNOSERVEIX");
              break;
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }
          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },
    comprovarTargeta() {
      axios
        .post(api.URL() + "/api/v1/targeta", {
          token: sessionStorage.getItem("webtpv21_token"),
          targeta: this.nTargeta,
          contrasenya: this.contrasenya,
        })
        .then((response) => {
          // TODO
          if (this.guardarDadesTargeta) {
            localStorage.setItem("webtpv21_targeta", this.nTargeta);
            localStorage.setItem("webtpv21_password", this.contrasenya);
          } else {
            localStorage.removeItem("webtpv21_targeta");
            localStorage.removeItem("webtpv21_password");
          }

          // Avisar si la targeta es nomes acumula punts
          if (response.data.nomesPunts) {
            this.$alert.show({
              title: "",
              text: this.$t("TARGETANOMESPUNTS"),
            });
          }

          this.dlgTargeta2 = false;
        })
        .catch((error) => {
          var msg = "";

          switch (error.response.data) {
            case "NOTARGETAVIP":
              msg = this.$t("NOTARGETAVIP");
              break;
            case "NOAQUESTTIPUSTARGETA":
              msg = this.$t("NOAQUESTTIPUSTARGETA");
              break;
            default:
              msg = this.$t("TARGETAINCORRECTA");
          }

          this.$alert.show({
            title: "",
            text: msg,
          });
        });
    },
    comprovarTargetaCli(cli) {
      axios
        .post(api.URL() + "/api/v1/targetacli", {
          token: sessionStorage.getItem("webtpv21_token"),
          cli: cli,
        })
        .then((response) => {
          // TODO
          if (this.guardarDadesTargeta) {
            localStorage.setItem("webtpv21_targeta", response.data.targeta);
            localStorage.setItem("webtpv21_password", response.data.contrasenya);
          } else {
            localStorage.removeItem("webtpv21_targeta");
            localStorage.removeItem("webtpv21_password");
          }

          // Avisar si la targeta es nomes acumula punts
          if (response.data.nomesPunts) {
            this.$alert.show({
              title: "",
              text: this.$t("TARGETANOMESPUNTS"),
            });
          }

          this.dlgTargeta2 = false;

          return true;
        })
        .catch((error) => {
          var msg = "";

          switch (error.response.data) {
            case "NOTARGETAVIP":
              msg = this.$t("NOTARGETAVIP");
              break;
            case "NOAQUESTTIPUSTARGETA":
              msg = this.$t("NOAQUESTTIPUSTARGETA");
              break;
            default:
              msg = this.$t("TARGETAINCORRECTA");
          }

          this.$alert.show({
            title: "",
            text: msg,
          });
        });

      return false;
    },
    infoFidelity() {
      if (this.$i18n.locale == "ca") {
        return sessionStorage.getItem("webtpv21_urlInfoFidelityCat");
      } else {
        return sessionStorage.getItem("webtpv21_urlInfoFidelityCas");
      }
    },
    init() {
      addEventListener("resize", () => {
        this.mobil = innerWidth <= 1300;
      });
      ebus.$on("idioma", () => {
        this.grups();
      });
      // Dades targeta fidelitzacio
      this.fidelity = sessionStorage.getItem("webtpv21_fidelity");
      var targeta, password;
      targeta = localStorage.getItem("webtpv21_targeta");
      password = localStorage.getItem("webtpv21_password");

      if (targeta != null) {
        this.nTargeta = targeta;
        this.guardarDadesTargeta = true;
      }

      if (password != null) {
        this.contrasenya = password;
        this.guardarDadesTargeta = true;
      }
      let nomesBar = sessionStorage.getItem("webtpv21_nomesBar")
      if (nomesBar != null) {
        this.nomesBar = nomesBar == "S";
        if (nomesBar == "S" && this.fidelity == "S") {
          // Veure si va en mode app
          let app = sessionStorage.getItem("webtpv21_app");
          let cli = sessionStorage.getItem("webtpv21_cli");
          // app to UpperCase
          if (app != null) {
            app = app.toUpperCase();
          }

          if (app == "S" && cli != null && cli != "" && cli != "0") {
            this.comprovarTargetaCli(cli);
          } else {
            this.dlgTargeta1 = true;
          }
        }
      }

      // Barra de progres
      this.$emit("progresevent", 55);

      this.grups();

      // Items a la cistella
      var c = sessionStorage.getItem("webtpv21_cistella");
      if (c != null && c != "") this.cistella = JSON.parse(c);

      this.actualitzaBadge();

      // Val de bar
      var vb = sessionStorage.getItem("webtpv21_valbar");
      if (vb != null) this.valBar = vb;
      var tvb = sessionStorage.getItem("webtpv21_tvalbar");
      if (tvb != null) this.tvalBar = tvb;
      var dvb = sessionStorage.getItem("webtpv21_dvalbar");
      if (dvb != null) this.dvalBar = parseInt(dvb);

      // Scroll to top
      window.scrollTo(0, 0);
    }
  },
  activated() {
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
}

.link:hover {
  font-weight: bold;
}

.mdi {
  color: #e31d1a;
}
</style>
