<template>
  <div class="black--text">
    <!-- versio pc -->
    <div v-if="!mobil">
      <!--div
        style="
          border: 1px solid #d9d9d9;
          height: calc(100vh - 360px);
          overflow-y: auto;
          overflow-x: hidden;
        "
      -->
      <v-row justify="center" class="mt-5 mb-4">
        <v-col cols="12">
          <div class="text-h5 font-weight-bold" style="text-align: center; color: #e31d1a">
            {{ $t("SELECCIONAPRODUCTESBAR") }}
          </div>
        </v-col>
      </v-row>
      <div v-for="(item, index) in items" :key="index">
        <v-row justify="center">
          <v-col cols="6" align="center">
            <v-btn style="width: calc(100% - 210px); justify-content: left" class="mr-1 selector text-h7 font-weight-bold"
              aria-label="Descripción">{{ item.descripcio }}</v-btn>
            <v-btn @click="menys(index)" class="mr-1 selector text-h7 font-weight-bold" aria-label="Menos">-</v-btn>
            <v-btn class="mr-1 selector text-h7 font-weight-bold" aria-label="Unidades">{{
              item.unitats
            }}</v-btn>
            <v-btn class="selector text-h7 font-weight-bold" @click="mes(index)" aria-label="Más">+</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" align="center" class="mb-3">
            <v-img width="98%" :src="'data:image/jpeg;base64,' + item.imatgeWEB" :alt="item.descripcio"></v-img>
          </v-col>
        </v-row>
      </div>
      <!--/div-->
    </div>
    <!-- versio mobil -->
    <div v-else>
      <v-row justify="center" class="mb-7">
        <v-col cols="12">
          <div class="text-h6 font-weight-bold" style="text-align: center; color: #e31d1a">
            {{ $t("SELECCIONAPRODUCTESBAR") }}
          </div>
        </v-col>
      </v-row>

      <div v-for="(item, index) in items" :key="index">
        <v-row justify="center">
          <v-col cols="8" align="center">
            <v-btn style="width: 100%" class="mr-1 selector text-h7 font-weight-bold" aria-label="Descripción">{{
              item.descripcio }}</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8" align="center">
            <v-btn @click="menys(index)" class="mr-1 selector text-h7 font-weight-bold" aria-label="Menos">-</v-btn>
            <v-btn class="mr-1 selector text-h7 font-weight-bold" aria-label="Unidades">{{
              item.unitats
            }}</v-btn>
            <v-btn class="selector text-h7 font-weight-bold" @click="mes(index)" aria-label="Más">+</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8" align="center" class="mb-3">
            <v-img width="98%" :src="'data:image/jpeg;base64,' + item.imatgeWEB" :alt="item.descripcio"></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row justify="center">
      <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" color="#58564f" class="mt-10  mr-2"
        @click="anterior()" aria-label="Volver">
        {{ $t("TORNAR") }}
      </v-btn>
      <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="ml-2 mt-10 " @click="seguent()"
        aria-label="Siguiente">
        {{ $t("SEGUENT") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import api from "../api.js";
import { ebus } from "../main.js";

const FN = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

export default {
  data() {
    return {
      mobil: window.innerWidth <= 1300,
      items: [],
      cistella: [],
      menu: {},
      selMenu: [],
      descripcioMenu: "",
      preuMenu: 0.0,
      quantitatMenu: 0,
    };
  },

  methods: {
    anterior() {
      this.entrarTotCistella();
      this.$router.push("/Preus");
    },

    seguent() {
      this.entrarTotCistella();
      this.$router.push("/Resum");
    },

    entrarTotCistella() {
      this.cistella = [];
      // entrar articles a la cistella
      this.items.forEach((article) => {
        if (article.unitats != 0) {
          this.entrarCistella({
            id: article.artiArticle,
            tipus: article.artiTipus,
            imatge: article.artiAspecte,
            nom: article.descripcio,
            preu: article.preu,
            quantitat: article.unitats,
            mn: article.mn,
          });
        }
      });

      sessionStorage.setItem(
        "webtpv21_cistella",
        JSON.stringify(this.cistella)
      );
    },

    entrarCistella(article) {
      // Article a la cistella
      article.grup = sessionStorage.getItem("webtpv21_botoneraReduida");
      // Mirar si es un menu
      if (article.tipus == "A11") {
        //this.imatgeMenu = article.imatge;
        this.descripcioMenu = article.nom;
        this.preuMenu = article.preu;
        this.quantitatMenu = article.quantitat;
        this.menu = article.mn;
        this.entrarMenu();

      } else {
        this.cistella.push(article);
      }
    },

    entrarMenu() {
      var nivsSelec = [];
      var increment = 0.0;
      // var nivells = 0;

      // this.menu.nivells.forEach((element) => {
      //   if (!element.automatic) ++nivells;
      // });

      // if (Object.keys(this.selMenu).length != nivells) {
      //   this.$alert.show({ title: "", text: this.$t("TRIAMENU") });
      //   return;
      // }

      // Entrar nivells automatics
      this.menu.nivells.forEach(function (nv) {
        if (nv.automatic) {
          nv.articles.forEach(function (art) {
            var nivSelec = {
              idNivell: nv.id,
              automatic: nv.automatic,
              idArticle: art.id,
              descripcio: art.descripcio,
            };

            increment += art.incrementPreu;

            nivsSelec.push(nivSelec);
          });
        }
      });
      /*
      // Entrar articles seleccionats en els nivells
      var menu = this.menu;
      this.selMenu.forEach(function (art, index) {
        var nivSelec = {
          idNivell: menu.nivells[index].id,
          automatic: menu.nivells[index].automatic,
          idArticle: art.id,
          descripcio: art.descripcio,
        };

        increment += art.incrementPreu;

        nivsSelec.push(nivSelec);
      });
      */
      var item = {
        id: this.menu.id,
        grup: sessionStorage.getItem("webtpv21_botoneraReduida"),
        tipus: "A11",
        imatge: this.imatgeMenu,
        nom: this.descripcioMenu,
        preu: this.preuMenu + increment,
        quantitat: this.quantitatMenu,
        nivells: nivsSelec,
        detall: false,
      };

      this.cistella.push(item);
    },

    mes(index) {
      var item = this.items[index];

      if (item.unitats < 99) {
        ++item.unitats;
        Vue.set(this.items, index, item);
      }
    },

    menys(index) {
      var item = this.items[index];

      if (item.unitats > 0) {
        --item.unitats;
        Vue.set(this.items, index, item);
      }
    },

    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },

    format(n) {
      return FN.format(n);
    },

    articles() {
      axios
        .post(api.URL() + "/api/v1/articles", {
          token: sessionStorage.getItem("webtpv21_token"),
          idioma: sessionStorage.getItem("webtpv21_idioma"),
          id: sessionStorage.getItem("webtpv21_botoneraReduida"),
          carregaMenu: true,
        })
        .then((response) => {
          this.items = response.data.articles;

          // Omplir unitats a 0
          this.items.forEach((val) => {
            val.unitats = 0;
          });

          // Actualitzar unitats des de la cistella (si ja se n'havien escollit)
          var c = sessionStorage.getItem("webtpv21_cistella");
          if (c != null && c != "") {
            this.cistella = JSON.parse(c);
            this.items.forEach((article) => {
              this.cistella.forEach((artCistella) => {
                if (article.artiArticle == artCistella.id) {
                  article.unitats = artCistella.quantitat;
                }
              });
            });
          }
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({ title: "", text: msg, onClose: onClose });
        });
    },

    init() {
      addEventListener("resize", () => {
        this.mobil = innerWidth <= 1300;
      });
      ebus.$on("idioma", () => {
        this.articles();
      });

      // Barra de progres
      this.$emit("progresevent", 55);

      // Carregar articles
      this.articles();
    },
  },

  activated() {
    this.init();
  },

  created() {
    this.init();
  },
};
</script>


<style scoped>
.link {
  text-decoration: none;
}

.link:hover {
  font-weight: bold;
}

.mdi {
  color: #e31d1a;
}

.selector {
  background: #384f80;
  color: white !important;
}
</style>
   