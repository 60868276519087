 <template>

    <v-card outlined class="card">
      <v-container>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-img
            class="mt-4 mb-4"
            width="50px"
            :src="'data:image/jpeg;base64,' + imatge"
            :alt="descripcio"
          ></v-img>
        </v-col>
        <v-col align-self="end">
          <div
            class="v-label ml-2 mr-2 font-weight-bold  descripcio"
            style="color: #e31d1a; height: 40px;"
          >
            {{ descripcio }}
          </div>
        </v-col>
      </v-row>
      </v-container>
    </v-card>

</template>

<script>
export default {
  props: ["descripcio", "imatge"],
};
</script>

<style scoped>
.card {
  border-color: #e31d1a;
  border-width: 2px;
  border-radius: 10px;
}

.descripcio {
  text-align: center;
  font-size: 13px;
}
</style>
