<template>
  <div class="black--text">
    <v-row justify="center">
      <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 boto-tornar" to="/Resum"
        aria-label="Volver">
        {{ $t("TORNAR") }}
      </v-btn>
    </v-row>
    <div v-html="infoComunicacions"></div>
    <v-row justify="center">
      <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 boto-tornar" to="/Resum"
        aria-label="Volver">
        {{ $t("TORNAR") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      infoComunicacionsCas: sessionStorage.getItem("webtpv21_infoComunicacionsCas"),
      infoComunicacionsCat: sessionStorage.getItem("webtpv21_infoComunicacionsCat"),
      infoComunicacions: "",
    };
  },
  '$i18n.locale': function (newVal) {
    if (newVal == "ca") {
      this.infoComunicacions = this.infoComunicacionsCat;
    } else {
      this.infoComunicacions = this.infoComunicacionsCas;
    }
  },
  methods: {
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    init() {
      if (this.$i18n.locale == "ca") {
        this.infoComunicacions = this.infoComunicacionsCat;
      } else {
        this.infoComunicacions = this.infoComunicacionsCas;
      }
    }
  },
  activated() {
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style>
.boto-tornar {
  background-color: #58564f;
}
</style>
