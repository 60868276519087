 <template>
  <v-col>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card elevation="0">
          <table>
            <tr>
              <td>
                <v-img
                  width="65px"
                  :src="'data:image/jpeg;base64,' + imatge"
                  :alt="descripcio"
                ></v-img>
              </td>
              <td class="botogrup">
                <div class="ml-2 mb-1">
                  <div v-if="hover" class="botogrup-hover">
                    {{ descripcio }}
                    <hr style="border: 1px solid; width: 100%" />
                  </div>
                  <div v-else>
                    {{ descripcio }}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  props: ["descripcio", "imatge", "triat"],
};
</script>

<style scoped>
.botogrup {
  font-weight: bold;
  color: black;
  vertical-align: bottom;
}

.botogrup-hover {
  color: #e31d1a;
  font-size: 16px;
}

/* subratllat */
hr {
  color: #e31d1a;
}
</style>
