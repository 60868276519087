<template>
  <div class="black--text">
    <!-- versio pc -->
    <div v-if="!mobil">
      <div style="border: 1px solid #d9d9d9">
        <div style="width: 90%; margin: 0 auto">
          <v-row>
            <v-col cols="4">
              <v-row v-if="!nomesBar" class="mr-9">
                <v-col>
                  <v-img class="mt-8" :src="'data:image/jpeg;base64,' + poster" :alt="nomPelicula"></v-img>
                </v-col>
              </v-row>
              <v-row v-if="!nomesBar" class="mr-9">
                <!-- TODO -->
                <v-col id="infoPelicula" style="color: white; background-color: #e31d1a; margin: 11px">
                  <div class="mb-2" style="font-size: 14pt; font-weight: bold">
                    {{ nomPelicula }}
                  </div>
                  <div style="font-weight: bold">
                    <div class="mb-2">
                      {{ cinema }}
                    </div>
                    <div class="mb-2">{{ data }}</div>
                    <div class="mb-2">{{ hora }}h, sala {{ sala }}</div>
                    <div class="mb-2">{{ $t("FILA") }} {{ fila }}</div>
                    <div class="mb-2">{{ $t("BUTACA") }} {{ nButaques }}</div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else class="mr-9 mt-4">
                <!-- TODO -->
                <v-col id="infoPelicula" style="color: white; background-color: #e31d1a; margin: 11px">
                  <div class="mb-2" style="font-size: 14pt; font-weight: bold">
                    {{ nomPelicula }}
                  </div>
                  <div style="font-weight: bold">
                    <div class="mb-2">
                      {{ cinema }}
                    </div>
                    <div class="mb-2">{{ data }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <div v-if="!nomesBar">
                <div class="mt-8" style="background-color: #f0f0f0; padding: 3px">
                  <h4 class="ml-1">{{ $t("RESUMENTRADES") }}</h4>
                </div>
                <div v-for="(item, idx) in resumEntrades" :key="idx">
                  <v-row>
                    <v-col cols="5">
                      <div>{{ item.descripcio }}</div>
                    </v-col>
                    <v-col cols="1" class="text-right">
                      <div>{{ item.quantitat }}</div>
                    </v-col>
                    <v-col cols="3" class="text-right">
                      <div v-if="item.promocio == false">{{ item.fmtPreu }}</div>
                    </v-col>
                    <v-col cols="3" class="text-right">
                      <div>{{ item.fmtTotal }}</div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else class="mt-8"></div>
              <div v-if="resumCompra && resumCompra.length > 0">
                <div class="mt-4" style="background-color: #f0f0f0; padding: 3px">
                  <h4 class="ml-1">{{ $t("RESUMBAR") }}</h4>
                </div>
                <div v-for="(item, idx) in resumCompra" :key="idx">
                  <v-row>
                    <v-col cols="5">
                      <div>{{ item.descripcio }}</div>
                    </v-col>
                    <v-col cols="1" class="text-right">
                      <div>{{ item.quantitat }}</div>
                    </v-col>
                    <v-col cols="3" class="text-right">
                      <div>{{ item.fmtPreu }}</div>
                    </v-col>
                    <v-col cols="3" class="text-right">
                      <div>{{ item.fmtTotal }}</div>
                    </v-col>
                  </v-row>
                  <!-- Detall menu -->
                  <table class="ml-2" style="font-size: 10px; color: black">
                    <tr v-for="(detall, index) in item.detallMenu" :key="index">
                      <td>
                        {{ detall }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr class="mt-3 mb-3" />
              <!-- <v-row class="text-caption">
                <v-col cols="5" sm="7"></v-col>
                <v-col cols="4" sm="3">
                  <div>{{ $t("SUBTOTAL") }}</div>
                </v-col>
                <v-col cols="3" sm="2" class="text-right">
                  <div>{{ subtotal }}</div>
                </v-col>
              </v-row> -->
              <!-- <v-row class="mt-n7 text-caption">
                <v-col cols="6" sm="8"></v-col>
                <v-col cols="3" sm="2">
                  <div>{{ nomIva }} {{ percentIva }}%</div>
                </v-col>
                <v-col cols="3" sm="2" class="text-right">
                  <div>{{ iva }}</div>
                </v-col>
              </v-row> -->

              <v-row class="mt-n6">
                <v-col cols="5" sm="7"></v-col>
                <v-col cols="4" sm="3">
                  <h4>{{ $t("TOTAL", [nomIva]) }}</h4>
                </v-col>
                <v-col cols="3" sm="2" class="text-right">
                  <h4>{{ total }}</h4>
                </v-col>
              </v-row>

              <div class="mt-4" style="background-color: #f0f0f0; padding: 3px">
                <h4 class="ml-1">{{ $t("DADESCONTACTE") }}</h4>
              </div>
              <v-row align="baseline" class="mt-1">
                <v-col cols="4">
                  <div>{{ $t("NOM") }}</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="nom" color="black" outlined dense hide-details aria-label="Nombre">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline" class="mmt-n11">
                <v-col cols="4">
                  <div>{{ $t("COGNOMS") }}</div>
                </v-col>
                <v-col cols="8" class="mt-n2">
                  <v-text-field v-model="cognoms" color="black" outlined dense hide-details aria-label="Apellidos">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4">
                  <div>{{ $t("EMAIL") }}</div>
                </v-col>
                <v-col cols="8" class="mt-n2">
                  <v-text-field v-model="email" color="black" outlined dense hide-details aria-label="Correo">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4">
                  <div>{{ $t("CONFIRMAREMAIL") }}</div>
                </v-col>
                <v-col cols="8" class="mt-n2">
                  <v-text-field v-model="confEmail" color="black" outlined dense hide-details
                    aria-label="Confirmar correo">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4">
                  <div>{{ $t("TELEFONMOBIL") }}</div>
                </v-col>
                <v-col cols="8" class="mt-n2">
                  <v-text-field v-model="tel" color="black" outlined dense hide-details aria-label="Teléfono">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="0" sm="4"></v-col>
                <v-col cols="12" sm="8">
                  <div style="font-size: 12px" v-html="xuleta"></div>
                </v-col></v-row>

              <v-row v-if="tipusWeb != 'O'" class="mt-n4">
                <v-col cols="0" sm="4"></v-col>
                <v-col cols="12" sm="8">
                  <v-checkbox hide-details v-model="guardarDades" aria-label="Guardar datos contacto">
                    <span slot="label" class="black--text">{{
                      $t("GUARDARDATOSCONTACTO")
                    }}</span>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row class="" style="padding-bottom: 0;">
                <v-col cols="0" sm="4"></v-col>
                <v-col cols="12" sm="8">
                  <v-row class="ml-0">
                    <v-checkbox hide-details v-model="acceptarCondicions" @click="dlgConformitat = true" aria-label="Aceptar condiciones"/>
                    <router-link to="/Contracte" class="mt-6 v-label" aria-label="Ver política de privacidad">{{
                      $t("ACCEPTOCONDICIONSCOMPRA")
                    }}</router-link>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="demanarMailing">
                <v-col cols="0" sm="4"></v-col>
                <v-col cols="12" sm="8">
                  <div>
                    <v-checkbox hide-details v-model="mailing"
                      style="display: inline-block; position: relative; top: 5px;" aria-label="Recibir comunicaciones"/>
                    <router-link slot="label" to="/InfoComunicacions" class="v-label"
                      v-html="rebreComunicats"></router-link>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Botons -->
          <v-row justify="center">
            <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 mr-2"
              @click="anterior()" aria-label="Volver">
              {{ $t("TORNAR") }}
            </v-btn>
            <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="ml-2 mt-10 mb-10"
              @click="pagar()" :disabled="!btnPagar" aria-label="Pagar">
              {{ $t("PAGAR") }}
            </v-btn>
          </v-row>
        </div>
      </div>
    </div>
    <!-- versio mobil -->
    <div v-else>
      <v-row class="mr-0">
        <v-col v-if="!nomesBar" cols="6">
          <v-img class="mt-n3 mb-n3 mr-0" :src="'data:image/jpeg;base64,' + poster" :alt="nomPelicula"></v-img>
        </v-col>
        <v-col v-if="nomesBar" class="ml-1" cols="12" style="background-color: #e31d1a; color: white">
          <div class="mb-2" style="font-size: 14pt; font-weight: bold">
            {{ nomPelicula }}
          </div>
          <div style="font-weight: bold">
            <div class="mb-2">{{ cinema }}</div>
            <div class="mb-2">{{ data }}</div>
          </div>
        </v-col>
        <v-col v-else cols="6" style="background-color: #e31d1a; color: white">
          <div class="mb-2" style="font-size: 14pt; font-weight: bold">
            {{ nomPelicula }}
          </div>
          <div style="font-weight: bold">
            <div class="mb-2">{{ cinema }}</div>
            <div class="mb-2">{{ data }}</div>
            <div class="mb-2">{{ hora }}h, sala {{ sala }}</div>
            <div class="mb-2">{{ $t("FILA") }} {{ fila }}</div>
            <div class="mb-2">{{ $t("BUTACA") }} {{ nButaques }}</div>
          </div>
        </v-col>
      </v-row>
      <div v-if="!nomesBar">
        <div class="mt-8" style="background-color: #f0f0f0; padding: 3px">
          <h4 class="ml-1">{{ $t("RESUMENTRADES") }}</h4>
        </div>
        <div v-for="(item, idx) in resumEntrades" :key="idx">
          <v-row>
            <v-col cols="5">
              <div>{{ item.descripcio }}</div>
            </v-col>
            <v-col cols="1" class="text-right">
              <div>{{ item.quantitat }}</div>
            </v-col>
            <v-col cols="3" class="text-right">
              <div>{{ item.fmtPreu }}</div>
            </v-col>
            <v-col cols="3" class="text-right">
              <div>{{ item.fmtTotal }}</div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else class="mt-8"></div>
      <div v-if="resumCompra && resumCompra.length > 0">
        <div class="mt-4" style="background-color: #f0f0f0; padding: 3px">
          <h4 class="ml-1">{{ $t("RESUMBAR") }}</h4>
        </div>
        <div v-for="(item, idx) in resumCompra" :key="idx">
          <v-row>
            <v-col cols="5">
              <div>{{ item.descripcio }}</div>
            </v-col>
            <v-col cols="1" class="text-right">
              <div>{{ item.quantitat }}</div>
            </v-col>
            <v-col cols="3" class="text-right">
              <div>{{ item.fmtPreu }}</div>
            </v-col>
            <v-col cols="3" class="text-right">
              <div>{{ item.fmtTotal }}</div>
            </v-col>
          </v-row>
          <!-- Detall menu -->
          <table class="ml-2" style="font-size: 10px; color: black">
            <tr v-for="(detall, index) in item.detallMenu" :key="index">
              <td>
                {{ detall }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <hr class="mt-3 mb-3" />
      <!-- <v-row class="text-caption">
        <v-col cols="4" sm="6"></v-col>
        <v-col cols="5" sm="4">
          <div>{{ $t("SUBTOTAL") }}</div>
        </v-col>
        <v-col cols="3" sm="2" class="text-right">
          <div>{{ subtotal }}</div>
        </v-col>
      </v-row> -->
      <!-- <v-row class="mt-n7 text-caption">
        <v-col cols="6" sm="8"></v-col>
        <v-col cols="3" sm="2">
          <div>{{ nomIva }} {{ percentIva }}%</div>
        </v-col>
        <v-col cols="3" sm="2" class="text-right">
          <div>{{ iva }}</div>
        </v-col>
      </v-row> -->

      <v-row class="mt-n6">
        <v-col cols="4" sm="6"></v-col>
        <v-col cols="5" sm="4">
          <h4>{{ $t("TOTAL", [nomIva]) }}</h4>
        </v-col>
        <v-col cols="3" sm="2" class="text-right">
          <h4>{{ total }}</h4>
        </v-col>
      </v-row>

      <div class="mt-4" style="background-color: #f0f0f0; padding: 3px">
        <h4 class="ml-1">{{ $t("DADESCONTACTE") }}</h4>
      </div>
      <v-row align="baseline" class="mt-1">
        <v-col cols="4">
          <div>{{ $t("NOM") }}</div>
        </v-col>
        <v-col cols="8">
          <v-text-field v-model="nom" color="black" outlined dense hide-details aria-label="Nombre">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="baseline" class="mmt-n11">
        <v-col cols="4">
          <div>{{ $t("COGNOMS") }}</div>
        </v-col>
        <v-col cols="8" class="mt-n2">
          <v-text-field v-model="cognoms" color="black" outlined dense hide-details aria-label="Apellidos">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="4">
          <div>{{ $t("EMAIL") }}</div>
        </v-col>
        <v-col cols="8" class="mt-n2">
          <v-text-field v-model="email" color="black" outlined dense hide-details aria-label="Correo">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="4">
          <div>{{ $t("CONFIRMAREMAIL") }}</div>
        </v-col>
        <v-col cols="8" class="mt-n2">
          <v-text-field v-model="confEmail" color="black" outlined dense hide-details aria-label="Confirmar correo">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="4">
          <div>{{ $t("TELEFONMOBIL") }}</div>
        </v-col>
        <v-col cols="8" class="mt-n2">
          <v-text-field v-model="tel" color="black" outlined dense hide-details aria-label="Teléfono">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="0" sm="4"></v-col>
        <v-col cols="12" sm="8">
          <div v-html="xuleta"></div>
        </v-col></v-row>

      <v-row v-if="tipusWeb != 'O'" class="mt-n4">
        <v-col cols="0" sm="4"></v-col>
        <v-col cols="12" sm="8">
          <v-checkbox hide-details v-model="guardarDades" :label="$t('GUARDARDATOSCONTACTO')" aria-label="Guardar datos de contacto">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row style="padding-bottom: 0;">
        <v-col cols="0" sm="4"></v-col>
        <v-col cols="12" sm="8">
          <v-row class="ml-0">
            <v-checkbox hide-details v-model="acceptarCondicions" @click="dlgConformitat = true" aria-label="Acceptar condiciones" />
            <router-link to="/Contracte" class="mt-6 v-label">{{
              $t("ACCEPTOCONDICIONSCOMPRA")
            }}</router-link>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="demanarMailing">
        <v-col cols="0" sm="4"></v-col>
        <v-col cols="12" sm="8">
          <div>
            <v-checkbox hide-details v-model="mailing" style="display: inline-block;" aria-label="Recibir comunicados"/>
            <router-link slot="label" to="/InfoComunicacions" class="v-label" v-html="rebreComunicats"></router-link>
          </div>
        </v-col>
      </v-row>

      <!-- Botons -->
      <v-row justify="center">
        <v-btn id="tornar" depressed style="border-radius: 17px" min-width="150" class="mt-10 mb-10 mr-2"
          @click="anterior()" aria-label="Volver">
          {{ $t("TORNAR") }}
        </v-btn>
        <v-btn depressed style="border-radius: 17px" min-width="150" color="#547604" class="ml-2 mt-10 mb-10"
          @click="pagar()" :disabled="!btnPagar" aria-label="Pagar">
          {{ $t("PAGAR") }}
        </v-btn>
      </v-row>
    </div>

    <!-- Dialeg conformitat -->
    <v-dialog v-model="dlgConformitat" width="550">
      <v-card style="border-radius: 8px">
        <v-card-title>
          <div class="mb-3"></div>
          <v-btn icon @click="
            dlgConformitat = false;
          acceptarCondicions = false;
          " style="position: absolute; right: 10px; top: 10px" aria-label="Cerrar">
            <v-icon color="black" id="alert-tancar" aria-label="Cerrar">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col align="center" class="font-weight-bold v-label black--text">
              <h2>{{ $t("REVISASELECCIO") }}</h2>
            </v-col>
          </v-row>
          <div class="font-weight-bold" style="
              color: #e31d1a;
              margin: 23px;
              border: 2px solid #e31d1a;
              padding: 15px;
            ">
            <div class="mb-2">{{ $t("CINEMA:") }} {{ cinema }}</div>
            <div v-if="!nomesBar" class="mb-2">{{ $t("PELICULA:") }} {{ nomPelicula }}</div>
            <div class="mb-2">{{ $t("DIA:") }} {{ data }}</div>
            <div v-if="!nomesBar">
              {{ $t("SESSIO:") }} {{ hora }}h, sala {{ sala }}
              <div class="mb-2">
                {{ $t("SESSIO:") }} {{ hora }}h, sala {{ sala }}
              </div>
              <div class="mb-2">{{ $t("FILA:") }} {{ fila }}</div>
              <div class="mb-2">{{ $t("BUTACA:") }} {{ nButaques }}</div>
            </div>
          </div>

          <div class="d-flex">
            <div>
              <v-icon class="ml-6" color="#e31d1a" size="40" aria-label="Información">mdi-alert</v-icon>
            </div>
            <div class="ml-6 mr-6" v-html="xuleta"></div>
          </div>

          <v-row class="mt-6">
            <v-col align="center">
              <v-btn depressed color="#000000" class="dlgBtnPC mb-3 ml-3" @click="
                dlgConformitat = false;
              acceptarCondicions = true;
              " aria-label="Aceptar">
                {{ $t("DACORD") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-action />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data() {
    return {
      mobil: window.innerWidth <= 1300,
      poster: null,
      nomPelicula: "",
      cinema: "",
      data: "",
      hora: "",
      sala: "",
      fila: "",
      nButaques: "",
      resumEntrades: [],
      resumCompra: [],
      subtotal: "",
      iva: "",
      total: "",
      nom: "",
      cognoms: "",
      email: "",
      confEmail: "",
      tel: "",
      xuleta: "",
      xuletaCat: "",
      xuletaCas: "",
      rebreComunicats: "",
      rebreComunicatsCat: "",
      rebreComunicatsCas: "",
      missatgeRebreComunicats: "",
      missatgeRebreComunicatsCat: "",
      missatgeRebreComunicatsCas: "",
      guardarDades: false,
      acceptarCondicions: false,
      btnPagar: true,
      nomIva: "IVA",
      percentIva: 0,
      dlgConformitat: false,
      demanarMailing: false,
      mailing: false,
      tipusWeb: sessionStorage.getItem("webtpv21_tipusWeb"),
      nomesBar: false,
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
      if (newVal == "ca") {
        this.xuleta = this.xuletaCat;
        this.rebreComunicats = this.rebreComunicatsCat;
        this.missatgeRebreComunicats = this.missatgeRebreComunicatsCat;
      } else {
        this.xuleta = this.xuletaCas;
        this.rebreComunicats = this.rebreComunicatsCas;
        this.missatgeRebreComunicats = this.missatgeRebreComunicatsCas;
      }
    }
  },
  methods: {
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },

    anterior() {
      if (sessionStorage.getItem("webtpv21_bar") == "S") {
        if (sessionStorage.getItem("webtpv21_botoneraReduida") == "") {
          this.$router.push("/Bar");
        } else {
          this.$router.push("/BarUCC");
        }
      } else this.$router.push("/Preus");
    },
    pagar() {
      // Normanitzar dades formulari
      this.nom = this.nom.trim();
      this.cognoms = this.cognoms.trim();
      this.email = this.email.trim().toLowerCase();
      this.confEmail = this.confEmail.trim().toLowerCase();
      this.tel = this.tel.trim();

      // Nomes numeros al telefon
      var t = "";
      for (var n = 0; n < this.tel.length; n++) {
        if (this.tel[n] >= "0" && this.tel[n] <= "9") {
          t += this.tel[n];
        }
      }
      this.tel = t;

      // Validar dades formulari
      if (this.nom.length == 0) {
        this.$alert.show({
          title: "",
          text: this.$t("OMPLECAMPNOM"),
        });
        return;
      }
      /*
      if (this.cognoms.length == 0) {
        this.$alert.show({
          title: "",
          text: this.$t("OMPLECAMPCOGNOMS"),
        });
        return;
      }
      */
      if (this.email.length == 0) {
        this.$alert.show({
          title: "",
          text: this.$t("OMPLECAMPEMAIL"),
        });
        return;
      }
      if (this.tel.length == 0) {
        this.$alert.show({
          title: "",
          text: this.$t("OMPLECAMPTELEFON"),
        });
        return;
      }
      if (this.email != this.confEmail) {
        this.$alert.show({
          title: "",
          text: this.$t("OMPLECAMPCONFIGMACIOEMAIL"),
        });
        return;
      }
      if (!this.emailIsValid(this.email)) {
        this.$alert.show({
          title: "",
          text: this.$t("EMAILNOVALID"),
        });
        return;
      }
      if (!this.acceptarCondicions) {
        this.$alert.show({
          title: "",
          text: this.$t("ACCEPTARCONDICIONSCOMPRA"),
        });
        return;
      }

      // this.dlgConformitat = true;
      this.pagar2();
    },
    pagar2() {
      this.btnPagar = false;

      // Facebook pixel
      var pixel = sessionStorage.getItem("webtpv21_faceBookPixel");
      if (pixel != null && pixel != "") {
        console.log("Generant 'InitiateCheckout' Facebook pixel...");

        var fbq = window.fbq || function () { };
        fbq("track", "InitiateCheckout");
      }

      // Google site tag
      if (sessionStorage.getItem("webtpv21_googleSiteTag")) {
        var gst = JSON.parse(
          sessionStorage.getItem("webtpv21_googleSiteTag")
        );
        if (gst != null && gst.pixelGoogleBeginCheckout != "") {
          var gtag = window.gtag || function () { };
          gtag("event", "conversion", {
            send_to: gst.pixelGoogleBeginCheckout,
          });
        }
      }

      if (this.guardarDades) {
        localStorage.setItem("webtpv21_nom", this.nom);
        localStorage.setItem("webtpv21_cognoms", this.cognoms);
        localStorage.setItem("webtpv21_email", this.email);
        localStorage.setItem("webtpv21_confEmail", this.confEmail);
        localStorage.setItem("webtpv21_tel", this.tel);
      } else {
        localStorage.removeItem("webtpv21_nom");
        localStorage.removeItem("webtpv21_cognoms");
        localStorage.removeItem("webtpv21_email");
        localStorage.removeItem("webtpv21_confEmail");
        localStorage.removeItem("webtpv21_tel");
      }

      axios
        .post(api.URL() + "/api/v1/reservar", {
          token: sessionStorage.getItem("webtpv21_token"),
          nom: this.nom,
          cognoms: this.cognoms,
          email: this.email,
          telefon: this.tel,
          urlRetorn: api.URL() + "/api/v1/payret",
          idioma: sessionStorage.getItem("webtpv21_idioma"),
          valBar: sessionStorage.getItem("webtpv21_valbar"),
          mailing: this.mailing,
          nomesBar: sessionStorage.getItem("webtpv21_nomesBar"),
        })
        .then((response) => {
          if (response.data.mode == "E") {
            if (response.data.ecopaynet.PPS_URL) {
              localStorage.setItem(
                "webtpv21_ecopaynet",
                JSON.stringify(response.data.ecopaynet)
              );
              // Anar a EcoPaynet
              const form = document.createElement("form");
              form.method = "post";
              form.action = response.data.ecopaynet.PPS_URL;

              for (var k in response.data.ecopaynet) {
                if (k != "PPS_URL") {
                  const field = document.createElement("input");
                  field.type = "hidden";
                  field.name = k;
                  field.value = response.data.ecopaynet[k];

                  form.appendChild(field);
                }
              }
              document.body.appendChild(form);
              form.submit();
            } else {
              this.$router.push("/Final");
            }
          } else {
            //TODO ==========================================================
            if (response.data.redsys.Ds_URL) {
              // Anar a RedSys
              const form = document.createElement("form");
              form.method = "post";
              form.action = response.data.redsys.Ds_URL;

              for (k in response.data.redsys) {
                if (k != "Ds_URL") {
                  const field = document.createElement("input");
                  field.type = "hidden";
                  field.name = k;
                  field.value = response.data.redsys[k];

                  form.appendChild(field);
                }
              }
              document.body.appendChild(form);
              form.submit();
            } else {
              this.$router.push("/Final");
            }
            // ==============================================================
          }
        })
        .catch((error) => {
          this.btnPagar = true;

          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "ERRORENVALS":
              msg = this.$t("ERRORENVALS");
              break;
            case "SALAPLENA":
              msg = this.$t("SALAPLENA");
              break;
            case "SESSIOBLOQUEJADA":
              msg = this.$t("SESSIOBLOQUEJADA");
              break;
            case "NOESPOTRESERVAR":
              msg = this.$t("NOESPOTRESERVAR");
              break;

            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({
            title: "",
            text: msg,
            onClose: onClose,
          });
        });
    },

    init() {
      addEventListener("resize", () => {
        this.mobil = innerWidth <= 1300;
      });

      // Comprovar si nomes es bar
      let nomesBar = sessionStorage.getItem("webtpv21_nomesBar")
      if (nomesBar != null) {
        this.nomesBar = nomesBar == "S";
      }

      // Barra de progres
      this.$emit("progresevent", 77);

      if (this.tipusWeb != "O") {
        var nom = localStorage.getItem("webtpv21_nom");
        var cognoms = localStorage.getItem("webtpv21_cognoms");
        var email = localStorage.getItem("webtpv21_email");
        var confEmail = localStorage.getItem("webtpv21_confEmail");
        var tel = localStorage.getItem("webtpv21_tel");

        if (nom != null) {
          this.nom = nom;
          this.guardarDades = true;
        }
        if (cognoms != null) this.cognoms = cognoms;
        if (email != null) this.email = email;
        if (confEmail != null) this.confEmail = confEmail;
        if (tel != null) this.tel = tel;
      }

      // Items a la cistella
      var compra = [];
      var c = sessionStorage.getItem("webtpv21_cistella");
      if (c != null && c != "") {
        var cistella = JSON.parse(c);

        cistella.forEach(function (item) {
          var nivells = [];

          if (item.nivells)
            item.nivells.forEach(function (item) {
              nivells.push({
                idNivell: item.idNivell,
                idArticle: item.idArticle,
                descripcio: item.descripcio,
                automatic: item.automatic,
              });
            });

          compra.push({
            id: item.id,
            grup: item.grup,
            quantitat: parseInt(item.quantitat),
            dte: item.dte,
            val: item.val,
            nivells: nivells,
          });
        });
      }

      axios
        .post(api.URL() + "/api/v1/resum", {
          token: sessionStorage.getItem("webtpv21_token"),
          compra: compra,
          idioma: sessionStorage.getItem("webtpv21_idioma"),
          nomesBar: sessionStorage.getItem("webtpv21_nomesBar"),
        })
        .then((response) => {
          this.poster = response.data.poster;
          this.nomPelicula = response.data.nom;
          this.cinema = response.data.cinema;
          this.data = response.data.data;
          this.hora = response.data.hora;
          this.sala = response.data.sala;
          this.fila = response.data.fila;
          this.nButaques = response.data.nButaques;
          this.resumEntrades = response.data.resumEntrades;
          this.resumCompra = response.data.resumCompra;
          this.subtotal = response.data.fmtSubtotal;
          this.iva = response.data.fmtIva;
          this.total = response.data.fmtTotal;
          this.xuletaCat = response.data.xuletaCat;
          this.xuletaCas = response.data.xuletaCas;
          this.rebreComunicatsCat = response.data.rebreComunicatsCat;
          this.rebreComunicatsCas = response.data.rebreComunicatsCas;
          this.missatgeRebreComunicatsCat = response.data.missatgeRebreComunicatsCat;
          this.missatgeRebreComunicatsCas = response.data.missatgeRebreComunicatsCas;

          sessionStorage.setItem("webtpv21_infoComunicacionsCas", response.data.missatgeRebreComunicatsCas);
          sessionStorage.setItem("webtpv21_infoComunicacionsCat", response.data.missatgeRebreComunicatsCat);

          if (this.$i18n.locale == "ca") {
            this.xuleta = response.data.xuletaCat;
            this.rebreComunicats = response.data.rebreComunicatsCat;
            this.missatgeRebreComunicats = response.data.missatgeRebreComunicatsCat;
          } else {
            this.xuleta = response.data.xuletaCas;
            this.rebreComunicats = response.data.rebreComunicatsCas;
            this.missatgeRebreComunicats = response.data.missatgeRebreComunicatsCas;
          }

          this.nomIva = response.data.nomIva;
          this.percentIva = response.data.percentIva.toString();
          if (response.data.contacteNom) {
            this.nom = response.data.contacteNom;
            this.cognoms = "";
          }
          if (response.data.contacteEmail) {
            this.email = response.data.contacteEmail;
            this.confEmail = response.data.contacteEmail;
          }
          if (response.data.contacteTelefon) {
            this.tel = response.data.contacteTelefon;
          }
          if (response.data.demanarMailing) {
            this.demanarMailing = response.data.demanarMailing;
          }
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({
            title: "",
            text: msg,
            onClose: onClose,
          });
        });
      // scroll to top
      window.scrollTo(0, 0);
    },
  },
  activated() {
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style>
hr {
  border: none;
  height: 2px;
  color: #000000;
  background-color: #000000;
}

#tornar.v-btn {
  background-color: #58564f !important;
}
</style>
