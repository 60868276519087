 <template>
  <v-col>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card elevation="0" style="height: 200px; width: 150px">
          <v-card-title></v-card-title>
          <v-card-text class="card-boto">
            <v-row justify="center">
              <v-col align="center">
                <v-img
                  width="100px"
                  :src="'data:image/jpeg;base64,' + imatge"
                  :alt="descripcio"
                ></v-img>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col>
                <div class="mb-6 vermell-boto" v-if="hover">
                  <div v-html="descripcio"/>
                  <!-- hr style="border: 1px solid; width: 100%" /-->
                </div>
                <div class="mb-6 negre-boto" v-else v-html="descripcio"></div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action />
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  props: ["descripcio", "imatge"],
};
</script>

<style scoped>
.card-boto {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
}

.vermell-boto {
  font-size: 13px;
  color: #e31d1a;
}

.negre-boto {
  color: black;
}

/* subratllat */
hr {
  color: #e31d1a;
}
</style>
