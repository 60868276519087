<template>
  <div class="black--text">
    <!-- versio pc -->
    <div v-if="!mobil">
      <iframe :src="'data:application/pdf;base64,' + pdf" width="100%" style="height: 60vh"></iframe>
      <div class="mt-10 mb-10">
        <v-row justify="center">
          <v-btn depressed x-large style="border-radius: 17px" min-width="200" color="#547604" class="mt-10 mb-10"
            @click="adeu()" aria-label="Finalizar">
            {{ $t("FINALITZAR") }}
          </v-btn>
        </v-row>
      </div>
    </div>
    <!-- versio mobil -->
    <div v-else>
      <div class="mt-10 mb-10">
        <v-row v-if="error == null" justify="center">
          <v-btn depressed x-large style="border-radius: 17px" min-width="200" color="black" class="mt-10 mb-10"
            @click="download()" aria-label="Descargar">
            {{ $t("DOWNLOAD") }}
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn depressed x-large style="border-radius: 17px" min-width="200" color="#547604" class="mt-10 mb-10"
            @click="adeu()" aria-label="Finalizar">
            {{ $t("FINALITZAR") }}
          </v-btn>
        </v-row>
      </div>
    </div>
    
    <v-dialog v-model="dialog" width="500" persistent>
      <v-sheet color="black" style="border-radius: 8px">
        <v-main>
          <v-btn icon @click="dialog = false" style="
              position: absolute;
              right: 0;
              top: 0;
              background-color: black !important;
            " aria-label="Cerrar">
            <v-icon color="#e31d1a">mdi-close</v-icon>
          </v-btn>
          <div class="ml-4 mr-4 mt-6" style="
              color: white;
              text-align: center;
              display: flex;
              flex-direction: column;
            ">
            <div v-html="text"></div>
            <br />
            <div>
              <v-btn color="#e31d1a" class="mr-3 dlgBtnFinal" @click="dialog = false" aria-label="Aceptar">{{
                $t("ACCEPTAR") }}</v-btn>
              <!--
              <v-btn color="#e31d1a" class="ml-3 dlgBtnFinal" @click="correu()"
                >{{ $t("SI") }}
              </v-btn>
              -->
            </div>
          </div>
        </v-main>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="dialogCorreu" width="500" persistent>
      <v-sheet color="black" style="border-radius: 8px">
        <v-main>
          <v-btn icon @click="dialogCorreu = false" style="
              position: absolute;
              right: 0;
              top: 0;
              background-color: black !important;
            " aria-label="Cerrar">
            <v-icon color="#e31d1a">mdi-close</v-icon>
          </v-btn>
          <div class="ml-4 mr-4 mt-6" style="
              color: white;
              text-align: center;
              display: flex;
              flex-direction: column;
            ">
            <div v-html="textCorreu1" class="mb-1"></div>
            <div v-html="textCorreu2" class="mb-3" style="font-size: 10px"></div>
            <div class="mr-6 ml-6">
              <v-text-field color="white" outlined dense autofocus v-model="correusAmics">
              </v-text-field>
            </div>
            <div>
              <v-btn color="#e31d1a" class="mr-3 dlgBtnFinal" @click="amics()">{{ $t("ENVIAR") }}</v-btn>
            </div>
          </div>
        </v-main>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";
import utf8 from "utf8";
import saveAs from "file-saver";

export default {
  data() {
    return {
      idioma: "",
      mobil: window.innerWidth <= 1300,
      dialog: false,
      dialogCorreu: false,
      text: "",
      textCorreu1: "",
      textCorreu2: "",
      error: null,
      errorMsg: "",
      correusAmics: "",
      pdf: "",
    };
  },
  methods: {
    datahora() {
      // Retorna la data i hora actual en format YYMMDDHHmm
      var data = new Date();
      var any = data.getFullYear().toString().substr(-2);
      var mes = (data.getMonth() + 1).toString().padStart(2, "0");
      var dia = data.getDate().toString().padStart(2, "0");
      var hora = data.getHours().toString().padStart(2, "0");
      var minuts = data.getMinutes().toString().padStart(2, "0");
      
      return any + mes + dia + hora + minuts;
    },
    download() {
      /*
      var element = document.createElement("a");
      element.href = "data:application/pdf;base64," + this.pdf;
      element.target = "_blank";

      if (this.idioma == "ca") {
        element.download = "entrades.pdf";
      } else {
        element.download = "entradas.pdf";
      }

      //element.style.display = "none";
      document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      */
      const byteCharacters = atob(this.pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], {
        type: "application/pdf",
      });

      if (this.idioma == "ca") {
        saveAs(blob, "entrades" + this.datahora() + ".pdf");
        // saveAs(blob, "entrades.pdf");
      } else {
        saveAs(blob, "entradas" + this.datahora() + ".pdf");
        // saveAs(blob, "entradas.pdf");
      }
    },
    amics() {
      axios
        .post(api.URL() + "/api/v1/correus", {
          token: sessionStorage.getItem("webtpv21_token"),
          correus: this.correusAmics,
          idioma: sessionStorage.getItem("webtpv21_idioma"),
        })
        .then(() => {
          this.adeu();
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({
            title: "",
            text: msg,
            onClose: onClose,
          });
        });
    },
    adeu() {
      window.location.href = sessionStorage.getItem("webtpv21_URLRetorn");
    },
    correu() {
      this.dialog = false;
      this.dialogCorreu = true;
    },
    init() {
      this.idioma = sessionStorage.getItem("webtpv21_idioma");
      // Barra de progres
      this.$emit("progresevent", 100);

      // PDF
      axios
        .post(api.URL() + "/api/v1/pdf", {
          token: sessionStorage.getItem("webtpv21_token"),
        })
        .then((response) => {
          this.pdf = response.data.pdf;
          var impEntrades = response.data.impEntrades;
          // var pixel = response.data.pixel;
          console.log(pixel, impEntrades);

          // Facebook pixel
          var pixel = sessionStorage.getItem("webtpv21_faceBookPixel");
          if (pixel != null && pixel != "") {
            if (this.$route.query.PPS_ErrorCode) {
              console.log("El pagament no ha anat bé, no genero 'Pixel'");
            } else {
              console.log("Generant 'Purchase' Facebook Pixel...");
              var fbq = window.fbq || function () { };
              fbq("init", pixel);
              fbq("track", "Purchase", {
                value: parseFloat(impEntrades),
                currency: "EUR",
              });
            }
          }

          // Google site tag
          if (sessionStorage.getItem("webtpv21_googleSiteTag")) {
            var gst = JSON.parse(sessionStorage.getItem("webtpv21_googleSiteTag"));
            if (gst != null && gst.pixelGooglePurchase != "") {
              if (this.$route.query.PPS_ErrorCode) {
                console.log("El pagament no ha anat bé, no genero 'GTag'");
              } else {
                console.log("Generant 'Purchase' Google site tag...");
                var gtag = window.gtag || function () { };
                gtag("event", "conversion", {
                  send_to: gst.pixelGooglePurchase,
                  value: parseFloat(impEntrades),
                  currency: "EUR",
                  transaction_id: "",
                });
              }
            }
          }

          // if (pixel) {
          //   if (this.$route.query.PPS_ErrorCode) {
          //     console.log("El pagament no ha anat bé, no genero 'pixel'");
          //   } else {
          //     // Facebook pixel
          //     console.log("Generant 'Purchase' Facebook pixel...");
          //     window.fbq("track", "Purchase", {
          //       value: parseFloat(impEntrades),
          //       currency: "EUR",
          //     });
          //     // Google site tag
          //     window.gtag("event", "conversion", {
          //       send_to: "AW-540424636/mUjtCKzBrqkDELzz2IEC",
          //       value: parseFloat(impEntrades),
          //       currency: "EUR",
          //       transaction_id: "",
          //     });
          //   }
          // }
        })
        .catch((error) => {
          var msg;
          var onClose = {};
          switch (error.response.data.error) {
            case "TOKENCADUCATOINVALID":
              msg = this.$t("TOKENCADUCATOINVALID");
              onClose = () => {
                this.adeu();
              };
              break;
            default:
              msg = this.$t("ERRORINTERN");
              onClose = () => {
                this.adeu();
              };
          }

          this.$alert.show({
            title: "",
            text: msg,
            onClose: onClose,
          });
        });

      if (this.$route.query.PPS_ErrorCode) {
        this.error = unescape(this.$route.query.PPS_ErrorCode);
        this.errorMsg = utf8.decode(
          unescape(this.$route.query.PPS_ErrorDescription)
        );
        console.log("<<<<<<", this.error, this.errorMsg);

        this.$alert.show({
          text:
            this.$t("ERRORENTRANSACCIO1") +
            this.error +
            this.$t("ERRORENTRANSACCIO2"),
          title: this.errorMsg,
          onClose: () => {
            this.adeu();
          },
        });
      } else {
        this.error = null;
        this.text = this.$t("MISSATGEFINAL");
        //this.text = this.$t("DIALEGCORREU1");
        this.textCorreu1 = this.$t("DIALEGCORREU2");
        this.textCorreu2 = this.$t("DIALEGCORREU3");
        this.dialog = true;
      }
    },
  },
  activated() {
    this.init();
  },
  mounted() {
    this.init();
  }
};
</script>
<style>
.dlgBtnFinal {
  width: 120px;
  text-transform: none;
}

.dlgInputFinal {
  margin: 0 auto;
  display: block;
}

.v-input__slot {
  background-color: white !important;
  caret-color: black;
}
</style>
